var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.errored && !_vm.results)?_c('vue-horizontal',_vm._l((Array(5).keys()),function(i){return _c('div',{key:'rel_sk_' + i,staticClass:"related-section",style:('height: ' + _vm.panel_section_height + 'px;')},[_c('div',{staticStyle:{"padding-top":"5px"}},[_c('b-skeleton-img',{attrs:{"height":_vm.panel_section_height - 15 + 'px'}})],1)])}),0):_vm._e(),(_vm.errored || (_vm.results && _vm.results.length === 0))?_c('div',[_vm._v(" No related documents found ")]):_vm._e(),(_vm.results && _vm.results.length > 0)?_c('vue-horizontal',_vm._l((_vm.results),function(result){return _c('div',{key:'rel_' + result.rank,staticClass:"related-section",style:('height: ' + _vm.panel_section_height + 'px;')},[_c('div',{staticClass:"related-document-row",attrs:{"data-url":"#","title":"View related study"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12",style:('max-height: ' +
              (_vm.panel_section_height - 10) +
              'px; overflow-y:auto; margin-top:5px;')},[_c('span',{staticClass:"title"},[_c('router-link',{attrs:{"to":{
                  name: 'document',
                  params: {
                    doc_id: result.metadata.id,
                    metadata: result.metadata,
                  },
                }}},[_c('div',{staticClass:"truncated-title"},[_vm._v(" "+_vm._s(_vm.normalizeTitle(result.metadata.title))+" ")])])],1),_c('div',{staticClass:"study-country"},[(result.metadata.country)?_c('span',[_vm._v(_vm._s(result.metadata.country[0])+", ")]):_vm._e(),(result.metadata.year)?_c('span',[_vm._v(_vm._s(result.metadata.year))]):_vm._e()]),_c('div',{staticClass:"survey-stats"},[(result.metadata.date_published)?_c('span',[_vm._v("Created on: "+_vm._s(_vm.getDate(result.metadata.date_published))+" ")]):_vm._e(),_c('br'),(result.metadata.views)?_c('span',[_vm._v("Views: "+_vm._s(result.metadata.views))]):_vm._e()])])])])])}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }