<template>
  <div class="text-left">
    <header>
      <h1 class="blog-post-title mb-3" dir="auto">
        {{ page_title }}
      </h1>
    </header>
    <div>
      <article class="blog-post">
        <p>
          Topic models and embedding models produce vector representation of
          texts that correspond to the topic distribution or that contain
          semantic information of the documents, respectively, which can be used
          to measure similarity between texts.
        </p>
        <p>
          We have transformed the documents in our database into these vectors.
          These vectors were generated by the topic models and word embedding
          models that we have trained. These document vectors are indexed which
          allows us to easily retrieve relevant documents given a new reference
          document. The reference document is cleaned, processed, and
          transformed by the same pipeline and model that were used in
          generating the indexed vectors.
        </p>

        <p>
          This demo allows users to see how topic and embedding models could
          power a new paradigm of content discovery. These models enable us to
          leverage the full information contained in a document for searching
          relevant content. Users can compare the relevant documents produced by
          both the topic model and the word embedding model for the same
          reference document. The reference document can be provided in multiple
          ways. Users can upload a pdf or a text file. Alternatively, a url that
          links to a pdf or a text file may also be used.
        </p>
        <AnalyzeYourDocumentWidget />
        <hr />
        <PageFooter :url="share_url" :share_text="share_text" />
      </article>
    </div>
  </div>
</template>

<script>
import saveState from "vue-save-state";

import PageFooter from "../common/PageFooter";
import AnalyzeYourDocumentWidget from "../common/AnalyzeYourDocumentWidget";


export default {
  name: "AnalyzeYourDocumentPage",
  props: {
    page_title: String,
    share_url: String,
    share_text: String,
  },
  components: {
    PageFooter,
    AnalyzeYourDocumentWidget,
  },
  mixins: [saveState],
  mounted() {
    // window.vm = this;
    // console.log(this.model_names);
    this.model_options.topic_model.model_id = null;
    this.model_options.word2vec.model_id = null;

    this.$simpleTrack("explore--" + this.lodash.kebabCase(this.page_title), "explore", "Explore")
  },
  data() {
    return {
      // common
      tabIndex: 0,
      page_sizes: this.$config.pagination.page_sizes,
      page_window: this.$config.pagination.page_window,
      init_click: false,

      next_override: true,
      model_names: {
        word2vec: "word2vec",
        topic_model: "topic_model",
      },
      model_options: {
        topic_model: {
          upload_nlp_api_url: this.$config.search_url.lda.file,
          url_nlp_api_url: this.$config.search_url.lda.url,
          model_run_info_id: "",
          model_id: null,
          curr_page_num: 1,
          next: 0,
          num_pages: 0,
          from_result: 0,
          hits: [],
          match_stats: [],
          total: { value: null, message: null },
          curr_size: this.$config.pagination.size,
        },
        word2vec: {
          upload_nlp_api_url: this.$config.search_url.word2vec.file,
          url_nlp_api_url: this.$config.search_url.word2vec.url,
          model_run_info_id: "",
          model_id: null,
          curr_page_num: 1,
          next: 0,
          num_pages: 0,
          from_result: 0,
          hits: [],
          match_stats: [],
          total: { value: null, message: null },
          curr_size: this.$config.pagination.size,
        },
      },

      errored: false,
      loading: false,

      analyzed_document_data: null,
      upload_analyze_document_url: null,
      url_analyze_document_url: null,
      analyze_document_model_id: null,

      indicators_data: null,
      indicators_model_id: null,

      url: "",
      uploaded_file: null,
      file_input: null,
      url_cache: "",
      selectedInput: "file_upload",
      uploadOptions: [
        {
          html: "<strong>Upload PDF or TXT file</strong>",
          value: "file_upload",
        },
        {
          html: "<strong>Input URL to PDF or TXT file</strong>",
          value: "url_upload",
        },
      ],
    };
  },
  computed: {
    stateReady() {
      if (this.model_options[this.model_names.word2vec].model_id !== null) {
        if (
          this.model_options[this.model_names.topic_model].model_id !== null
        ) {
          if (this.url !== "" || this.uploaded_file !== null) return true;
        }
      }
      return false;
    },
    apiUrl() {
      if (this.selectedInput === "file_upload") {
        return this.model_option.upload_nlp_api_url;
      } else {
        return this.model_option.url_nlp_api_url;
      }
    },
    model_option() {
      return this.model_options[this.selectedModel];
    },
    analyzeDocumentParams() {
      const formData = new FormData();
      formData.append("model_id", this.analyze_document_model_id);

      if (this.selectedInput === "file_upload") {
        formData.append("file", this.uploaded_file);
      } else {
        formData.append("url", this.url);
      }
      return formData;
    },
    relatedIndicatorsParams() {
      const formData = new FormData();
      formData.append("model_id", this.indicators_model_id);

      if (this.selectedInput === "file_upload") {
        formData.append("file", this.uploaded_file);
      } else {
        formData.append("url", this.url);
      }
      return formData;
    },
    apiParams() {
      const formData = new FormData();
      formData.append("model_id", this.model_option.model_id);

      if (this.selectedInput === "file_upload") {
        formData.append("file", this.uploaded_file);
      } else {
        formData.append("url", this.url);
      }
      formData.append("from_result", this.model_option.from_result);
      formData.append("size", this.model_option.curr_size);
      return formData;
    },
    selectedModel() {
      if (this.tabIndex === 0) {
        // Document analysis
        return this.model_names.topic_model;
      } else if (this.tabIndex === 1) {
        // Embedding model
        return this.model_names.word2vec;
      } else if (this.tabIndex === 2) {
        // Topic model
        return this.model_names.topic_model;
      } else if (this.tabIndex === 3) {
        // Related data
        return this.model_names.word2vec;
      } else {
        return null;
      }
    },
    hasUploadedFile() {
      if (this.uploaded_file !== null) {
        if (this.uploaded_file.name !== undefined) {
          return true;
        }
      }
      return false;
    },
    no_more_hits() {
      var next_from =
        this.model_option.curr_page_num * this.model_option.curr_size;

      var no_more_hits = false;
      if (next_from > this.model_option.total.value) {
        no_more_hits = true;
      }

      return no_more_hits;
    },
    has_hits() {
      return this.model_option.hits.length > 0 && !this.no_more_hits;
    },
  },
  methods: {
    setCurrSize(size) {
      this.model_options[this.selectedModel].curr_size = size;
      this.sendSearch();
    },
    getSaveStateConfig() {
      return {
        cacheKey: "filterTopicSharePage",
      };
    },
    onModelSelectWord2Vec(result) {
      this.model_options[this.model_names.word2vec].upload_nlp_api_url =
        this.$config.search_url[result.model_name].file;
      this.model_options[this.model_names.word2vec].url_nlp_api_url =
        this.$config.search_url[result.model_name].url;
      this.model_options[this.model_names.word2vec].model_id =
        result.model_run_info_id;

      this.indicators_model_id = result.model_run_info_id;

      console.log(result.model_run_info_id);
    },
    onModelSelectTopicModel(result) {
      this.model_options[this.model_names.topic_model].upload_nlp_api_url =
        this.$config.search_url[result.model_name].file;
      this.model_options[this.model_names.topic_model].url_nlp_api_url =
        this.$config.search_url[result.model_name].url;
      this.model_options[this.model_names.topic_model].model_id =
        result.model_run_info_id;

      this.upload_analyze_document_url =
        this.$config.analyze_document_url[result.model_name].analyze_file;
      this.url_analyze_document_url =
        this.$config.analyze_document_url[result.model_name].analyze_url;
      this.analyze_document_model_id = result.model_run_info_id;

      console.log(result.model_run_info_id);
    },
    sendSearch: function (page_num = 1) {
      console.log(page_num);
      console.log(this.selectedModel);

      this.loading = true;
      console.log(this.model_options[this.selectedModel]);
      this.model_options[this.selectedModel].curr_page_num = page_num;
      var from = (page_num - 1) * this.model_option.curr_size;

      if (
        from > this.model_options[this.selectedModel].total.value &&
        !this.next_override
      ) {
        return;
      }
      // this.model_options[this.selectedModel].hits = [];
      this.model_options[this.selectedModel].from_result = from;

      this.$http
        .post(this.apiUrl, this.apiParams)
        .then((response) => {
          this.model_options[this.selectedModel].hits = response.data.hits;
          this.model_options[this.selectedModel].match_stats =
            response.data.result;
          this.model_options[this.selectedModel].total = response.data.total;
          this.model_options[this.selectedModel].next =
            this.model_options[this.selectedModel].curr_page_num + 1;
          this.model_options[this.selectedModel].num_pages = Math.floor(
            this.model_options[this.selectedModel].total.value /
              this.model_options[this.selectedModel].curr_size
          );
          if (
            this.model_options[this.selectedModel].total.value %
              this.model_options[this.selectedModel].curr_size >
            0
          ) {
            this.model_options[this.selectedModel].num_pages += 1;
          }
        })
        .finally(() => (this.loading = false));
    },
    analyzeDocument() {
      this.loading = true;
      const analyze_document_url =
        this.selectedInput === "file_upload"
          ? this.upload_analyze_document_url
          : this.url_analyze_document_url;

      console.log(analyze_document_url);

      this.$http
        .post(analyze_document_url, this.analyzeDocumentParams)
        .then((response) => {
          this.analyzed_document_data = response.data;
          this.loading = false;
        });
    },
    getRelatedIndicators() {
      this.loading = true;
      const all_indicators_endpoint =
        this.selectedInput === "file_upload"
          ? "/get_similar_indicators_from_file"
          : "/get_similar_indicators_from_url";

      this.$http
        .post(
          this.$config.extra_url.all + all_indicators_endpoint,
          this.relatedIndicatorsParams
        )
        .then((response) => {
          this.indicators_data = response.data;
          this.loading = false;
        });
    },
    fileUpload(event) {
      this.uploaded_file = event.target.files[0];
    },
    removeFile() {
      this.uploaded_file = null;
      this.file_input = null;
    },
    setAnalyzedDocLoading(loading) {
      console.log(loading);
      this.loading = loading;
    },
    sendRequest() {
      this.init_click = true;
      this.loading = true;
      if (this.stateReady) {
        if (this.tabIndex === 0) {
          this.analyzeDocument();
        } else if (this.tabIndex === 3) {
          this.getRelatedIndicators();
        } else {
          this.sendSearch(this.model_option.curr_page_num);
        }
      }
    },
  },

  watch: {
    // selectedModel: function () {
    //   if (this.stateReady) {
    //     if (this.tabIndex === 2) {
    //       this.analyzeDocument();
    //     } else {
    //       this.sendSearch(this.model_option.curr_page_num);
    //     }
    //   }
    // },
    tabIndex: function () {
      this.sendRequest();
    },
    // $data: {
    //   handler: function (val, oldVal) {
    //     console.log(val, oldVal);
    //   },
    //   deep: true,
    // },
  },
};
</script>
<style scoped>
.file-input__label__similarity {
  /* border-right: 1px;
  border-right-color: rgb(206, 212, 218);
  border-right-style: solid; */
  border: 0px;
  border-top: 0px;
  border-bottom: 0px;
  /* margin-left: 10px; */
}
.wbg-uploaded-file__similarity {
  position: absolute; /*relative;*/
  max-width: 90%;
  /* margin-left: 10px; */
  max-height: 100%;
  margin-top: 1px;
}
.truncated-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}
</style>
