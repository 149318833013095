<template>
  <div id="appID">
    <Header />

    <main id="content">
      <router-view />
    </main>
    <!-- <footer>
      <div class="full-row-footer-components"></div>
    </footer> -->
    <!-- <footer class="footer"><div class="footer-container h-100"><div class="footer-row h-100"><div><ul class="footer-menu"><li class="f-label">WBG External Sites:</li><li><a target="_blank" rel="noopener" href="https://intranet.worldbank.org/">World Bank</a></li><li><a target="_blank" rel="noopener" href="http://www.miga.org/">MIGA</a></li><li><a target="_blank" rel="noopener" href="http://www.ifc.org/">IFC</a></li><li><a target="_blank" rel="noopener" href="http://www.worldbank.org/icsid/">ICSID</a></li></ul></div><div><ul class="footer-menu contact"><li>Emergency Contact Number (US) (202) 458-8888</li><li>© {{ year }} The World Bank Group, All Rights Reserved</li></ul></div></div></div></footer> -->

    <!-- <router-view class="view"></router-view> -->
    <Footer />
  </div>
</template>

<script>
// import SidePanel from "./components/SidePanel.vue";
import Header from "./components/HeaderNewNav.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  // data: function () {
  //   return { active_el: "home" };
  // },
  // methods: {
  //   activate: function (el) {
  //     this.active_el = el;
  //   },
  // },
  data: function() {
    return { year: (new Date()).getFullYear() };
  },
  components: {
    // SidePanel,
    Header,
    Footer,
  },
};
</script>

<style scoped>
#appID {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  /* text-align: center; */
  /* color: #2c3e50; */
  color: #212529;
  /* margin-top: 60px; */
}

/* Footer reference: https://datacatalog.worldbank.org/home */
.footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    z-index: 1000;
}

.h-100 {
    height: 100%!important;
}

.footer-container {
    max-width: 100vw;
    padding-right: 50px;
    padding-left: 50px;
    margin-right: auto;
    margin-left: auto;
}

.footer-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

footer {
    font-size: 12px;
    letter-spacing: .5px;
    line-height: 16px;
    color: #fff;
    background-color: #29303d;
    height: 48px;
}

.footer-menu {
    margin-bottom: 0;
}

footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer-menu li.f-label {
    border-right: 0;
    padding-right: 0;
}

.footer-menu li {
    display: inline-block;
    padding: 0 8px 0 0;
    margin-right: 8px;
    border-right: 1px solid hsla(0,0%,100%,.25);
    line-height: 16px;
}

.footer-menu li:last-child {
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
}

footer a, footer a:hover {
    color: #fff;
    text-decoration: none;
    outline: none;
}

</style>
