<template>
  <div>
    <!-- <footer>
      <section>
        <div class="pseudo-h4">Share</div>
        <nav class="nav sharing-icons mt-4">
          <a
            class="nav-item"
            :href="'https://www.facebook.com/sharer/sharer.php?u=' + url"
            title="Share on Facebook"
            ><span class="fab fa-facebook-f fa-lg" aria-hidden="true"></span
          ></a>
          <a
            class="nav-item"
            :href="
              'https://www.linkedin.com/shareArticle?mini=true&amp;url=' + url
            "
            title="Share on LinkedIn"
            ><span class="fab fa-linkedin-in fa-lg" aria-hidden="true"></span
          ></a>
          <a
            class="nav-item"
            :href="
              'https://twitter.com/intent/tweet?url=' +
              url +
              '&amp;text=' +
              share_text
            "
            title="Tweet this"
            ><span class="fab fa-twitter fa-lg" aria-hidden="true"></span
          ></a>
        </nav>
      </section>
    </footer> -->
  </div>
</template>

<script>
export default {
  name: "PageFooter",
  props: {
    url: String,
    share_text: String,
  },
};
</script>

<style scoped>
.pseudo-h4 {
  font-weight: bold;
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  line-height:1.2;
  margin-top: 0;
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
</style>
