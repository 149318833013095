var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.authors && _vm.valid_authors.length > 0)?_c('div',{staticClass:"authors",class:_vm.authors_class},[_vm._v(" Author(s): "),_vm._l((_vm.valid_authors),function(author){return _c('span',{key:author},[_c('router-link',{staticClass:"author-name",attrs:{"to":{
        name: 'search',
        query: {
          author: author,
          send_search: true,
          search_type: 'keyword',
          page: 1,
        },
      }}},[_vm._v(_vm._s(_vm.normalize_author(author)))]),_vm._v(", "),_c('a',{attrs:{"href":'https://orcid.org/orcid-search/search?searchQuery=' + author,"target":"_blank","title":"ORCID"}},[_c('img',{staticClass:"a-icon",attrs:{"src":"/static/files/orcid_128x128.png","title":"orcid logo","alt":"orcid logo"}})]),_c('a',{attrs:{"href":'https://scholar.google.com/scholar?q=' + author,"target":"_blank","title":"Google Scholar"}},[_c('img',{staticClass:"a-icon",attrs:{"src":"/static/files/google-scholar.png","title":"google scholar logo","alt":"google scholar logo"}})]),_c('a',{attrs:{"href":'https://www.semanticscholar.org/search?sort=relevance&q=' + author,"target":"_blank","title":"Semantic Scholar"}},[_c('img',{staticClass:"a-icon a-icon-last",attrs:{"src":"/static/files/ss-mstile-150x150.png","title":"semantic scholar logo","alt":"semantic scholar logo"}})])],1)})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }