<template>
  <div>
    <div style="float: right" v-if="show_full_screen"><a :href="full_window_url" target="_blank">Open in full screen</a></div>
    <div v-if="show_full_screen">
      <br/>
      <br/>
    </div>
    <div v-if="panel_layout === 'vertical'">
      <b-container fluid>
        <v-chart
          @dblclick="searchNode"
          @click="showDocData"
          @mouseover="isHighlighted"
          class="chart"
          refs="graphChart"
          :option="option2"
          :autoresize="true"
          :loading="loading"
        />
      </b-container>
      <hr/>
      <b-container v-if="!((doc_meta === null) || (doc_meta === undefined))">
        <a :href="'/document/' + doc_meta.id" target="_blank"><h5>{{ normalizeTitle(doc_meta.title) }}</h5></a>
        <Authors :authors="doc_meta.author" authors_class="authors-large" />
        <div>
          <span>Source: <a :href="doc_meta.url_pdf" target="_blank">{{ doc_meta.corpus }}</a></span>
          <br/>
          <span>Published: {{ doc_meta.date_published }}</span>
        </div>
        <div v-if="doc_meta.abstract" class="abstract">
          Abstract:
          <p>{{ doc_meta.abstract }}</p>

          <read-more
            more-str="read more"
            :text="doc_meta.abstract || ''"
            link="#"
            less-str="read less"
            :max-chars="500"
          ></read-more>
        </div>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid>
        <b-row>
          <b-col xs="12" md="8">
            <v-chart
              @dblclick="searchNode"
              @click="showDocData"
              @mouseover="isHighlighted"
              class="chart"
              refs="graphChart"
              :option="option2"
              :autoresize="true"
              :loading="loading"
            />
          </b-col>
          <b-col xs="12" md="4">
            <div class="h-meta-panel" v-if="!((doc_meta === null) || (doc_meta === undefined))">
              <a :href="'/document/' + doc_meta.id" target="_blank"><h5>{{ normalizeTitle(doc_meta.title) }}</h5></a>
              <Authors :authors="doc_meta.author" authors_class="authors-large" />
              <div>
                <span>Source: <a :href="doc_meta.url_pdf" target="_blank">{{ doc_meta.corpus }}</a></span>
                <br/>
                <span>Published: {{ doc_meta.date_published }}</span>
              </div>
              <div v-if="doc_meta.abstract" class="abstract">
                Abstract:
                <p>{{ doc_meta.abstract }}</p>

                <read-more
                  more-str="read more"
                  :text="doc_meta.abstract || ''"
                  link="#"
                  less-str="read less"
                  :max-chars="500"
                ></read-more>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Authors from "./Authors";
import ReadMore from "vue-read-more";
import Vue from "vue";

import { use } from "echarts/core";
import VChart from "vue-echarts";

import { TooltipComponent, LegendComponent } from "echarts/components";
import { GraphChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

use([TooltipComponent, LegendComponent, GraphChart, CanvasRenderer]);

Vue.use(ReadMore);
export default {
  name: "RelatedDocumentsGraph",
  components: {
    VChart,
    Authors,
    ReadMore,
  },
  props: {
    page_title: String,
    default_doc_id: {
      type: String,
      default: null,  // "wb_D12924239"
    },
    panel_layout: {
      type: String,
      default: "vertical"
    },
    show_full_screen: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    if (this.default_doc_id !== null) {
      this.updateWidget();
    }
  },
  computed: {
    full_window_url () {
      return this.$router.resolve({name: "related-documents-graph", params: {doc_id: this.doc_id}}).href;
    },
    blurContent: function () {
      return this.loading;
    },
    option2() {
      return {
        title: {
          text: "Related documents graph",
          top: "bottom",
          left: "right",
        },
        tooltip: {
          trigger: "item",
          formatter: this.tooltipCallback,
          extraCssText: "width:400px; white-space:pre-wrap;",
        },
        legend: [
          {
            data: this.graph.categories.map(function (a) {
              return a.name;
            }),
          },
        ],
        animationDuration: 1500,
        animationEasingUpdate: "quinticInOut",
        series: [
          {
            name: "Related documents",
            type: "graph",
            data: this.graph.nodes,
            links: this.graph.links.map(function (edge) {
              return {
                source: edge.source,
                target: edge.target,
                lineStyle: {
                  width: edge.sim,
                }
              };
            }),
            categories: this.graph.categories,
            roam: true,
            // label: {
            //   show: true,
            //   position: "right",
            //   formatter: "{b}",
            // },
            labelLayout: {
              hideOverlap: true,  // Change this to false to make all labels visible.
            },
            scaleLimit: {
              min: 0.01,
              max: 4,
            },
            lineStyle: {
              normal: {
                // color: "source",
                curveness: 0.3,
                // width: 0.75,
                opacity: 0.5,
              },
            },
            itemStyle: {
              opacity: 0.7,
            },
            focusNodeAdjacency: true,
          },
        ],
      };
    },
  },
  data() {
    return {
      navigator: window.navigator,
      api_link: null,
      graph: {
        nodes: [],
        links: [],
        categories: [],
      },
      doc_id: "",
      related_docs: [],
      loading: false,
      docs_metadata: null,
      doc_meta: null,
    };
  },
  methods: {
    updateWidget() {
      this.doc_id = this.default_doc_id
      this.getGraph(this.doc_id);
    },
    getDocMetadata(doc_id, emit_event = false) {
      if (emit_event) {
        this.$emit("docMetadataReceived", this.docs_metadata[doc_id]);
      }

      return this.docs_metadata[doc_id];
    },
    tooltipCallback(node) {
      if (node.dataType === "node") {
        var meta = this.getDocMetadata(node.data.doc_id);
        var author = meta.author == null ? "" : ('<span style="font-weight: bold;">Authors:</span> ' + meta.author.join(", ") + "<br/>");

        return '<span style="font-weight: bold;">Title:</span> ' + this.normalizeTitle(meta.title) + "<br/>" +
          author +
          '<span style="font-weight: bold;">Source:</span> ' + meta.corpus + "<br/>" +
          '<span style="font-weight: bold;">Published:</span> ' + meta.date_published + "<br/>";
      }
    },
    normalizeTitle(title) {
      if (title.endsWith(".pdf")) {
        title = title.slice(0, title.length - 4);
      }
      return title;
    },
    searchParams() {
      const params = new URLSearchParams();

      params.append("model_id", this.$config.default_model.word2vec.model_id);
      params.append("doc_id", this.doc_id);
      params.append("topn_docs", 10);
      params.append("topn_sub", 10);
      params.append("edge_thresh", 0.8);
      params.append("n_clusters", 5);
      params.append("metric_type", "IP");

      return params;
    },
    searchNode: function (event) {
      this.clicked_point = event;
      this.$emit("relatedNodeSearched", this.getDocMetadata(this.clicked_point.data.doc_id));
      this.getGraph(this.clicked_point.data.doc_id);
    },
    showDocData: function (event) {
      this.clicked_point = event;

      if (this.clicked_point.data.doc_id !== undefined) {
        this.doc_meta = this.getDocMetadata(this.clicked_point.data.doc_id);
        console.log("Clicked point:", this.clicked_point.data.doc_id);
        console.log("Clicked doc_meta:", this.doc_meta);
      }
    },
    isHighlighted: function (action) {
      this.highlighted_point = action;

      if (this.highlighted_point.data.doc_id !== undefined) {
        this.doc_meta = this.getDocMetadata(this.highlighted_point.data.doc_id);
        console.log("Hover point:", this.highlighted_point.data.doc_id);
        console.log("Hover doc_meta:", this.doc_meta);
      }
    },
    copyText() {
      this.navigator.clipboard.writeText(this.api_link);
    },
    getGraph: function (doc_id = null) {
      this.doc_meta = null;
      this.loading = true;
      if (typeof doc_id !== "string") {
        doc_id = this.doc_id;
      } else {
        this.doc_id = doc_id;
      }
      this.api_link = null;
      this.related_docs = [];

      this.$http
        .get(this.$config.nlp_api_url.word2vec + "/get_related_documents_graph", {
          params: this.searchParams(),
        })
        .then((response) => {
          var graph = response.data.graph_data;
          this.related_docs = response.data.similar_docs;
          this.docs_metadata = response.data.docs_metadata;

          this.doc_meta = this.getDocMetadata(this.doc_id, true);

          graph.nodes.forEach(function (node) {
            node.label = {
              show: node.symbolSize >= 75,
            };
            node.draggable = true;
            // node.x = node.y = null;
          });
          this.graph = graph;

          this.api_link =
            location.origin +
            this.$config.nlp_api_url.word2vec +
            "/get_related_documents_graph" +
            "?" +
            this.searchParams().toLocaleString();
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    default_doc_id: function () {
      this.updateWidget()
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.chart {
  height: 800px;
}

.badge-class {
  margin: 10px 5px 20px 0px;
  cursor: pointer;
}

.abstract p {
  margin-bottom: 0.1rem;
}

.h-meta-panel {
  max-height: 800px;
  overflow-y: scroll;
}

.tp-class {
  background: "rgba(0, 0, 0, 0.2)";
}
</style>
