<template>
  <div class="text-left">
    <h1>{{ page_title }}</h1>
    <div>
      <br />
      <p>
        We provide here some summary statistics on the document count and on the
        number of tokens (useful words) by source, over time, and by document
        type (2 categories) in the corpus used for our catalog (not the training
        corpus, which includes more sources). The corpus is currently composed
        in majority of documents from the World Bank.
      </p>

      <b-row>
        <b-col cols="12">
          <p>A subset of the documents in the corpus is tagged as relevant to the topic of forced displacement. Toggle the switch below to limit the data to the tagged collection.</p>
        </b-col>
        <b-col cols="4">
          <b-form-checkbox
            id="checkbox_app_tag_jdc"
            v-model="checkbox_app_tag_jdc"
            name="checkbox_app_tag_jdc"
            :value="true"
            :unchecked-value="false"
            switch
          >
            JDC collection
          </b-form-checkbox>
        </b-col>
      </b-row>

      <br />

      <VolumeChart ref="corpusChart" :loading="loading" field_name="source" />
      <br />
      <br />

      <p>
        We classified documents from the different sources into two main groups:
        <span style="font-weight: bold"> Project Documents</span> and
        <span style="font-weight: bold">Publications and Reports</span>. The
        charts below show the trend of the volume and token counts of published
        documents under each of these document types.
      </p>
      <br />
      <br />

      <VolumeChart
        ref="majorDocTypeChart"
        :loading="loading"
        field_name="document type"
      />
      <!-- <br />
      <br />
      <p>
        As documents may be tagged with multiple topics, we see that the
        breakdown by share of topics in the WB corpus have a total of more than
        100%. Nonetheless, comparison of share among topics will yield some
        insights regarding the general interest or popularity over time of one
        topic over other topics of comparison.
      </p>
      <br />
      <br />
      <VolumeChart
        ref="topicsSrcChart"
        :loading="loading"
        field_name="topic"
      /> -->
    </div>
  </div>
</template>

<script>
import VolumeChart from "../../common/VolumeChart";

export default {
  name: "Volume",
  components: {
    // VChart,
    VolumeChart,
  },
  props: {
    page_title: String,
  },
  mounted() {
    this.$http.get("/static/data/corpus_details.json").then((response) => {
      this.items = response.data;
    });
    this.getFullCorpusData();

    this.$simpleTrack("explore--" + this.lodash.kebabCase(this.page_title), "explore", "Explore")
  },
  computed: {
    defaultOptions() {
      return {
        title: {
          text: "Corpus volume",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          data: [],
        },
        toolbox: {
          feature: {
            // dataZoom: {
            //   yAxisIndex: "none",
            // },
            // restore: {},
            saveAsImage: {},
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: "{value}",
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
            start: 20,
            end: 100,
          },
          {
            start: 20,
            end: 100,
            handleIcon:
              "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
            handleSize: "50%",
            handleStyle: {
              color: "#fff",
              shadowBlur: 3,
              shadowColor: "rgba(0, 0, 0, 0.6)",
              shadowOffsetX: 2,
              shadowOffsetY: 2,
            },
          },
        ],
        series: [],
      };
    },
  },
  data: function () {
    return {
      loading: false,
      checkbox_app_tag_jdc: false,

      corpus: null,
      major_doc_type: null,
      topics_src: null,
    };
  },
  methods: {
    updateOption(data, value, label) {
      return {
        title: {
          text: "Topic profiles" + "(" + label + ")",
        },
        legend: {
          data: data[value].legend,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: data[value].year,
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: value === "share" ? "{value} %" : "{value}",
            },
          },
        ],
        series: data[value].series,
      };
    },
    getFullCorpusData: function () {
      this.loading = true;

      const params = new URLSearchParams();
      params.append("fields", "corpus");
      params.append("fields", "major_doc_type");
      // params.append("fields", "topics_src");
      params.append(
        "app_tag_jdc", this.checkbox_app_tag_jdc
      );

      this.$http
        .get(this.$config.corpus_url + "/get_corpus_volume_by", {
          params: params,
        })
        .then((response) => {
          let data = response.data;

          this.$refs.corpusChart.setData(data.corpus);
          this.$refs.majorDocTypeChart.setData(data.major_doc_type);
          // this.$refs.topicsSrcChart.setData(data.topics_src);

          this.loading = false;
        })

        .finally(() => {});
    },
  },
  watch: {
    checkbox_app_tag_jdc() {
      this.getFullCorpusData();
    }
  },
};
</script>
