<template>
  <!-- https://sdg-tracker.org/ -->
  <div>
    <b-container>
      <b-row class="justify-content-center sdg-group">
        <b-col class="sdg-name">
          <p class="lead">
            <a
              :href="result.url_data.replace('seriesCode=SDG_', 'seriesCode=')"
              target="_blank"
              >{{ result.name }}</a
            >
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="auto" class="align-self-center">
          <img width="175px" :src="result.img_uri" />
        </b-col>
        <b-col col="9" class="align-self-center">
          <p>
            <span class="sdg-title">Goal {{ result.goal }}</span
            >: {{ result.goal_title }}
          </p>
          <p>
            <span class="sdg-title">Target {{ result.target }}</span
            >: {{ result.target_title }}
          </p>
          <p>
            <span class="sdg-title">Indicator {{ result.indicator }}</span
            >: {{ result.indicator_description }}
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  name: "SDGCard",
  props: {
    result: Object,
  },
  mounted() {
    // window.sdg_card = this;
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  watch: {},
};
</script>
<style scoped>
.sdg-name {
  margin-bottom: 15px;
}
.sdg-group {
  margin-top: 20px !important;
  /* margin-bottom: 75px !important; */
}
.sdg-title {
  font-weight: bold;
}
</style>