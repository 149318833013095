var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"blog-header wbg-internal-header"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"col-12 mb-2"},[_c('div',{attrs:{"id":"breadcrumbs"}},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")]),_c('span',{staticClass:"wbg-breadcrumb-separator"},[_vm._v("/")]),_c('router-link',{attrs:{"to":"/explore"}},[_vm._v("Explore")]),_c('span',{staticClass:"wbg-breadcrumb-separator"},[_vm._v("/")]),_c('router-link',{attrs:{"to":"#"}},[_vm._v(_vm._s(_vm.currentPageTitle))])],1)]),_c('div',{staticClass:"col-12"},[_c('i',{staticClass:"fas fa-search fa-2x",attrs:{"aria-hidden":"true"}}),_c('h1',[_vm._v(_vm._s(_vm.currentPageTitle))])])])]),_c('div',{staticClass:"container flowing"},[(_vm.toggleButtonShow)?_c('b-button',{staticClass:"sidebar-toggler",attrs:{"variant":_vm.aria_expanded ? 'light' : 'dark'},on:{"click":_vm.toggleButton}},[_vm._v(" "+_vm._s(_vm.aria_expanded ? "Collapse" : "Expand"))]):_vm._e(),_c('div',{staticClass:"row"},[_c('aside',{directives:[{name:"show",rawName:"v-show",value:(!_vm.aria_expanded),expression:"!aria_expanded"}],staticClass:"col-sm-3",attrs:{"id":"blog-sidebar"}},[_c('section',{staticClass:"sidebar-module"},[_c('ol',{staticClass:"list-unstyled"},[_c('li',[_c('router-link',{class:{ active: _vm.$route.name === 'explore_corpus' },attrs:{"to":"/explore/corpus/"}},[_vm._v("Corpus")])],1),_c('li',[_c('router-link',{staticClass:"wbg_sidebar second-level",class:{
                  active: _vm.$route.name === 'explore_sources',
                },attrs:{"to":"/explore/subcategories/sources/"}},[_vm._v("Sources")])],1),_c('li',[_c('router-link',{staticClass:"wbg_sidebar second-level",class:{
                  active: _vm.$route.name === 'explore_volume',
                },attrs:{"to":"/explore/subcategories/volume/"}},[_vm._v("Volume")])],1),_c('li',[_c('router-link',{staticClass:"wbg_sidebar second-level",class:{
                  active: _vm.$route.name === 'explore_geographic-coverage',
                },attrs:{"to":"/explore/subcategories/geographic-coverage/"}},[_vm._v("Geographic coverage")])],1),_c('li',[_c('router-link',{staticClass:"wbg_sidebar second-level",class:{ active: _vm.$route.name === 'explore_metadata' },attrs:{"to":"/explore/subcategories/metadata/"}},[_vm._v("Metadata")])],1),_c('li',[_c('router-link',{class:{
                  active: _vm.$route.name === 'explore_topic-composition',
                },attrs:{"to":"/explore/topic-composition/"}},[_vm._v("Topic composition")])],1),_c('li',[_c('router-link',{staticClass:"wbg_sidebar second-level",class:{
                  active: _vm.$route.name === 'explore_filtering-by-topic-share',
                },attrs:{"to":"/explore/subcategories/filtering-by-topic-share/"}},[_vm._v("Filtering by topic share")])],1),_c('li',[_c('router-link',{class:{ active: _vm.$route.name === 'explore_topic-profiles' },attrs:{"to":"/explore/topic-profiles/"}},[_vm._v("Topic profiles")])],1),_c('li',[_c('router-link',{class:{ active: _vm.$route.name === 'explore_word-embeddings' },attrs:{"to":"/explore/word-embeddings/"}},[_vm._v("Word embeddings")])],1),_c('li',[_c('router-link',{class:{
                  active: _vm.$route.name === 'explore_analyze-your-document',
                },attrs:{"to":"/explore/analyze-your-document/"}},[_vm._v("Analyze your document")])],1)])])]),_c('div',{staticClass:"blog-main",class:_vm.aria_expanded ? 'col-sm-12' : 'col-sm-9'},[_c('article',{staticClass:"blog-post"},[_c('div',{staticClass:"content-row"},[_c('router-view')],1),_c('hr')])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }