<template>
  <div>
    <b-row align-v="center">
      <b-col cols="10" sm="11">
        <b-form-input v-model="raw_text" placeholder="Enter word(s)" v-on:keyup.enter="getGraph"
          aria-label="Word embedding input" />
      </b-col>
      <b-col cols="2" sm="1">
        <GetAPILinkWidget :api_link="api_link" />
      </b-col>
    </b-row>

    <b-container class="related-words-container">
      <b-badge class="badge-class" @click="getGraph(rw.word)" v-for="rw in this.related_words" :key="rw.word + rw.id"
        variant="success">{{ rw.word }}
      </b-badge>
    </b-container>

    <b-container fluid>
      <v-chart @dblclick="searchNode" @mouseover="isHighlighted" class="chart" refs="graphChart" :option="option2"
        :autoresize="true" :loading="loading" />
    </b-container>
  </div>
</template>

<script>
import { use } from "echarts/core";
import VChart from "vue-echarts";

import { TooltipComponent, LegendComponent } from "echarts/components";
import { GraphChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

import GetAPILinkWidget from "./GetAPILinkWidget.vue"

use([TooltipComponent, LegendComponent, GraphChart, CanvasRenderer]);

export default {
  name: "WordEmbeddings",
  components: {
    VChart,
    GetAPILinkWidget,
  },
  props: {
    page_title: String,
    default_query: {
      type: String,
      default: "refugee",
    },
  },
  mounted() {
    this.getGraph(this.default_query);
  },
  computed: {
    blurContent: function () {
      return this.loading;
    },
    option2() {
      return {
        title: {
          text: "Related words graph",
          subtext: "Default layout",
          top: "bottom",
          left: "right",
        },
        tooltip: {},
        legend: [
          {
            data: this.graph.categories.map(function (a) {
              return a.name;
            }),
          },
        ],
        animationDuration: 1500,
        animationEasingUpdate: "quinticInOut",
        series: [
          {
            name: "Related word",
            type: "graph",
            data: this.graph.nodes,
            links: this.graph.links,
            categories: this.graph.categories,
            roam: true,
            label: {
              show: true,
              position: "right",
              formatter: "{b}",
            },
            labelLayout: {
              hideOverlap: true,  // false,
            },
            scaleLimit: {
              min: 0.4,
              max: 2,
            },
            lineStyle: {
              normal: {
                color: "source",
                curveness: 0.3,
                width: 1.25,
                opacity: 0.9,
              },
            },
            itemStyle: {
              opacity: 0.7,
            },
            focusNodeAdjacency: true,
          },
        ],
      };
    },
  },
  data() {
    return {
      api_link: null,
      graph: {
        nodes: [],
        links: [],
        categories: [],
      },
      raw_text: "",
      related_words: [],
      loading: false,
    };
  },
  methods: {
    searchParams() {
      const params = new URLSearchParams();

      params.append("model_id", this.$config.default_model.word2vec.model_id);
      params.append("raw_text", this.text);
      params.append("topn_words", 8);
      params.append("topn_sub", 5);
      params.append("edge_thresh", 0.8);
      params.append("metric", "cosine_similarity");

      return params;
    },
    searchNode: function (event) {
      this.clicked_point = event;
      this.getGraph(this.clicked_point.data.name);
    },
    isHighlighted: function (action) {
      this.highlighted_point = action;
    },
    getGraph: function (text = null) {
      this.loading = true;
      if (typeof text !== "string") {
        text = this.raw_text;
      } else {
        this.raw_text = text;
      }
      this.api_link = null;
      this.related_words = [];
      this.text = text;

      this.$http
        .get(this.$config.nlp_api_url.word2vec + "/get_similar_words_graph", {
          params: this.searchParams(),
        })
        .then((response) => {
          var graph = response.data.graph_data;
          this.related_words = response.data.similar_words;
          graph.nodes.forEach(function (node) {
            // node.label = {
            //   show: node.symbolSize > 5,
            // };
            node.draggable = true;
            // node.x = node.y = null;
          });
          this.graph = graph;

          this.api_link =
            location.origin +
            this.$config.nlp_api_url.word2vec +
            "/get_similar_words_graph" +
            "?" +
            this.searchParams().toLocaleString();
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.chart {
  height: 600px;
}

.badge-class {
  margin: 0px 5px 0px 0px;
  cursor: pointer;
  background-color: #3A539B;
}

.related-words-container {
  padding: 0px;
  margin: 10px 0px 40px 0px;
}
</style>
