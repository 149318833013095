<template>
    <div>
        <p>Your query: {{ stringQuery }}</p>
        <hr />
        <b-form-group v-for="(item, index) in items" :key="index">
            <b-input-group>
                <b-container fluid>
                    <b-row v-if="index > 0" align-v="center" no-gutters>
                        <b-form-group v-slot="{ ariaDescribedby }">
                            <b-form-radio-group :id="'radio-group-' + index" v-model="item.prefix"
                                :options="search_prefix" :aria-describedby="ariaDescribedby"
                                :name="'radio-group-' + index"></b-form-radio-group>
                        </b-form-group>
                    </b-row>
                    <b-row align-v="center" no-gutters>
                        <b-col cols="9" lg="7" class="mr-lg-1">
                            <b-form-input v-if="!isKeyword(item.selectedField)" v-model="item.text" />
                            <FieldKeywordSelect v-else :parent_keyword="item.text"
                                :field="item.selectedField.substring(0, item.selectedField.length - 1)"
                                @keywordSelected="(k) => { item.text = k.value; }" />
                        </b-col>
                        <b-col cols="3" lg="2" class="mr-lg-4">
                            <b-form-select v-model="item.selectedField">
                                <option v-for="option in options" :key="option.value" :value="option.value">
                                    {{ option.text }}
                                </option>
                            </b-form-select>
                        </b-col>
                        <b-col class="pt-2 pb-2">
                            <b-row align-v="center" align-h="between" no-gutters>
                                <b-col cols="3" lg="9">
                                    <b-form-checkbox switch v-model="item.isPhrase"
                                        label="Phrase">Phrase</b-form-checkbox>
                                    <!-- <b-form-checkbox switch v-model="item.isTranslate"
                                        label="Translate">Translate</b-form-checkbox> -->
                                </b-col>
                                <b-col cols="3" lg="3">
                                    <b-input-group-append class="float-right">
                                        <b-button @click="removeRow(index)" variant="outline-danger" size="sm">
                                            <i class="fa fa-minus"></i>
                                        </b-button>
                                    </b-input-group-append>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-container>
            </b-input-group>
        </b-form-group>

        <b-container style="margin-top:2rem;">
            <b-row align-h="between" no-gutters>
                <b-col cols="2">

                    <b-button @click="addRow" variant="success">
                        <div class="d-flex align-items-center">
                            <!-- <i class="fa fa-plus mr-2"></i><span class="d-none d-lg-block">Add</span> -->
                            <i class="fa fa-plus mr-2"></i><small>Add</small>
                        </div>
                    </b-button>
                </b-col>
                <b-col cols="2">
                    <b-button @click="doSearch" variant="primary" class="float-right">
                        <div class="d-flex align-items-center">
                            <!-- <i class="fa fa-search mr-2"></i><span class="d-none d-lg-block">Search</span> -->
                            <i class="fa fa-search mr-2"></i><small>Search</small>
                        </div>
                    </b-button>
                </b-col>
            </b-row>
        </b-container>

    </div>
</template>



<script>
import FieldKeywordSelect from "@/components/common/search/FieldKeywordSelect.vue";

export default {
    name: "AdvancedSearchPanel",
    components: {
        FieldKeywordSelect,
    },
    props: {
        baseQuery: String,
    },
    mounted() {
        this.reverseStringQuery(this.baseQuery);
    },
    data() {
        return {
            items: [],
            // items: [
            //     { text: "", selectedField: "", isPhrase: false, isTranslate: false, prefix: null }
            // ],
            options: [
                { text: "All", value: "" },
                { text: "Title", value: "title:" },
                { text: "Body", value: "body:" },
                { text: "Abstract", value: "abstract:" },
                { text: "Author", value: "author:" },
                { text: "Country", value: "country:" },
            ],
            search_prefix: ["AND", "OR", "NOT"]
        };
    },
    methods: {
        isKeyword(field) {
            // return ["author:", "country:"].includes(field);
            return ["country:"].includes(field);
        },
        addRow() {
            this.items.push({ text: "", selectedField: "", isPhrase: false, isTranslate: false, prefix: "AND" });
        },
        removeRow(index) {
            this.items.splice(index, 1);
        },
        doSearch() {
            this.$emit("advancedSearch", {
                query: this.stringQuery
            });
        },
        processQuery(query) {
            let parensCount = 0;
            let quotesCount = 0;
            let output = "";
            let parensStack = [];
            let quotesStack = [];

            for (let i = 0; i < query.length; i++) {
                if (query[i] === "(") {
                    parensCount += 1;
                    parensStack.push(i);
                } else if (query[i] === ")") {
                    parensCount -= 1;
                    let start = parensStack.pop();

                    if (parensCount === 0) {
                        output += query.substring(start, i + 1).replace(/\$/g, " ");
                    }
                }
                else if (query[i] === '"') {
                    if (quotesCount === 0) {
                        quotesCount += 1;
                        quotesStack.push(i);
                    } else {
                        quotesCount -= 1;
                        let start = quotesStack.pop();
                        if (quotesCount === 0) {
                            output += query.substring(start, i + 1).replace(/\$/g, " ");
                        }
                    }
                } else if (parensStack.length === 0 && quotesStack.length === 0) {
                    output += query[i];
                }
            }

            if (quotesCount > 0 || parensCount > 0) {
                return null;
            }

            return output;
        },
        reverseAuthorCountry(text, selectedField) {
            if (["author:", "country:"].includes(selectedField)) {
                text = text.replace(/\*/g, " ").trim();
            }
            return text;
        },
        reverseStringQuery(query) {
            let ADV_PREF = "adv:";

            if (query.startsWith(ADV_PREF)) {
                query = query.slice(ADV_PREF.length).trim();

                // Format the query so that it's
                // easy to parse
                query = query.replaceAll(":(", ": (");
                query = query.replaceAll(':"', ': "');
                query = this.processQuery(query.replace(/\s/g, "$"));
                if (query === null) {
                    this.items.push({ text: "", selectedField: "", isPhrase: false, isTranslate: false, prefix: null });
                    return;
                }

                let words = query.split("$");

                // Replace all spaces that are not between
                // quotation marks or parentheses
                // with the '$' character.
                // query = query.replace(/\s+(?=([^"()]*"[^"()]*?")*[^"()]*$|[^"()]*\([^"()]*?\)[^"()]*$)/g, "$");

                // [^ ()] *\([^ ()] *?\)[^ ()] * $
                // query = query.replace(/\s+(?=([^"]*"[^"]*?")*[^"]*$)/g, "$")
                // query = query.replace(/\$+(?=([^()]*\([^()]*?\)[^()]*$)/g, " ")

                let prefix = null;
                let text = "";
                let selectedField = "";
                let isPhrase = false;
                words.forEach((word) => {
                    if (word === "AND" || word === "OR" || word === "NOT") {
                        if (text !== "") {
                            this.items.push({
                                text: this.reverseAuthorCountry(text, selectedField),
                                selectedField: selectedField,
                                isPhrase: isPhrase,
                                prefix: prefix,
                                isTranslate: false
                            });
                        }
                        prefix = word;
                        text = "";
                        selectedField = "";
                        isPhrase = false;
                    } else if (word.startsWith("title:") || word.startsWith("body:") || word.startsWith("abstract:") || word.startsWith("author:") || word.startsWith("country:")) {
                        selectedField = word;
                    } else {
                        if (word.startsWith('"') && word.endsWith('"')) {
                            isPhrase = true;
                            text = word.substring(1, word.length - 1);
                        } else if (word.startsWith("(") && word.endsWith(")")) {
                            text = word.substring(1, word.length - 1);
                        }
                    }
                });
                if (text !== "") {
                    this.items.push({
                        text: this.reverseAuthorCountry(text, selectedField),
                        selectedField: selectedField,
                        isPhrase: isPhrase,
                        prefix: prefix,
                        isTranslate: false
                    });
                }
            }
            else {
                this.items.push({ text: "", selectedField: "", isPhrase: false, isTranslate: false, prefix: null });
            }
        },
        sanitizeQuery(text, isPhrase) {
            text = text.replace(/["()]/g, '');
            if (isPhrase) {
                text = text.replace(/[*]/g, ' ').trim();
            }
            return text;
        },
        toTitleCase(str) {
            return str.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        }
    },
    computed: {
        stringQuery() {
            let query = "";
            this.items.forEach((item) => {
                if (item.text !== "") {
                    if (item.prefix !== null) {
                        query += " " + item.prefix + " ";
                    }
                    if (item.selectedField !== "") {
                        query += item.selectedField;
                    }
                    if (item.isPhrase) {
                        // Sanitize any potential phrases
                        query += '"' + this.sanitizeQuery(item.text, item.isPhrase) + '"';
                    } else {
                        // Sanitize any potential phrases
                        let text = item.text
                        if (["author:", "country:"].includes(item.selectedField)) {
                            text = text.replace(/\*/g, " ").trim()

                            if (item.selectedField === "author:") {
                                text = this.toTitleCase(text)
                            }

                            text = '*' + text.replace(/\s+/g, "*") + '*'
                        }
                        query += '(' + this.sanitizeQuery(text, item.isPhrase) + ')';
                    }
                }
            });
            return query;
        }
    }
};
</script>

<style>
.fa {
  font-size: 1.5em;
}