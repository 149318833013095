var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"blog-header wbg-internal-header"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"col-12 mb-2"},[_c('div',{attrs:{"id":"breadcrumbs"}},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")]),_c('span',{staticClass:"wbg-breadcrumb-separator"},[_vm._v("/")]),_c('router-link',{attrs:{"to":"/methods"}},[_vm._v("Methods")]),_c('span',{staticClass:"wbg-breadcrumb-separator"},[_vm._v("/")]),_c('router-link',{attrs:{"to":"#"}},[_vm._v(_vm._s(_vm.currentPageTitle))])],1)]),_vm._m(0)])]),_c('div',{staticClass:"container flowing"},[_c('div',{staticClass:"row"},[_c('aside',{staticClass:"col-sm-3 blog-sidebar"},[_c('section',{staticClass:"sidebar-module"},[_c('ol',{staticClass:"list-unstyled"},[_c('li',[_c('router-link',{class:{ active: _vm.$route.name === 'methods' },attrs:{"to":"/methods"}},[_vm._v("Methods & Tools")])],1),_c('li',[_c('router-link',{staticClass:"wbg_sidebar second-level",class:{
                  active: _vm.$route.name === 'methods_text-acquisition',
                },attrs:{"to":"/methods/text-acquisition"}},[_vm._v("Text acquisition")])],1),_c('li',[_c('router-link',{staticClass:"wbg_sidebar second-level",class:{
                  active: _vm.$route.name === 'methods_text-preparation',
                },attrs:{"to":"/methods/text-preparation"}},[_vm._v("Text preparation")])],1),_c('li',[_c('router-link',{staticClass:"wbg_sidebar second-level",class:{
                  active: _vm.$route.name === 'methods_word-embeddings',
                },attrs:{"to":"/methods/word-embeddings"}},[_vm._v("Word embeddings")])],1),_c('li',[_c('router-link',{staticClass:"wbg_sidebar second-level",class:{
                  active: _vm.$route.name === 'methods_lda',
                },attrs:{"to":"/methods/lda"}},[_vm._v("LDA Topic Model")])],1),_c('li',[_c('router-link',{staticClass:"wbg_sidebar second-level",class:{
                  active: _vm.$route.name === 'methods_search-engine',
                },attrs:{"to":"/methods/search-engine"}},[_vm._v("Search engine")])],1),_c('li',[_c('router-link',{staticClass:"wbg_sidebar second-level",class:{
                  active: _vm.$route.name === 'methods_visualizations',
                },attrs:{"to":"/methods/visualizations"}},[_vm._v("Visualizations")])],1),_c('li',[_c('router-link',{staticClass:"wbg_sidebar second-level",class:{
                  active: _vm.$route.name === 'methods_github',
                },attrs:{"to":"/methods/github"}},[_vm._v("Github")])],1)])])]),_c('div',{staticClass:"col-sm-9 blog-main"},[_c('article',{staticClass:"blog-post"},[_c('div',{staticClass:"content-row"},[_c('router-view')],1),_c('hr')])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('i',{staticClass:"fas fa-search fa-2x",attrs:{"aria-hidden":"true"}}),_c('h1',[_vm._v("Methods & Tools")])])}]

export { render, staticRenderFns }