<template>
  <div class="model-select-wrapper">
    <model-select ref="modelSelect" :id="'model-select-' + field" :options="keywords" v-model="keyword"
      :placeholder="input_placeholder" class="wbg-model-select"></model-select>
  </div>
</template>

<script>
import { ModelSelect } from "vue-search-select";

export default {
  name: "FieldKeywordSelect",
  components: {
    ModelSelect,
  },
  props: {
    field: String,
    placeholder: String,
    parent_keyword: String,
  },
  created() {
    this.keywords = [];
    this.getKeywords();

    if (this.parent_keyword) {
      this.keyword = this.parent_keyword;
    }
  },
  computed: {
    input_placeholder() {
      if (this.placeholder !== undefined) {
        return this.placeholder;
      } else {
        return "Select " + this.field + "...";
      }
    },
  },
  data: function () {
    return {
      keyword: "",
      keywords: [],
    };
  },
  methods: {
    getKeywords() {
      var url = "/nlp/search/keywords/" + this.field;
      this.$httpCached.get(url).then((response) => {
        this.keywords = response.data.map((kw) => {
          return {
            value: kw,
            text: kw,
          };
        });
      });
      // this.keywords = [
      //   { value: "Benin", text: "Benin" },
      //   { value: "Belgium", text: "Belgium" },
      //   { value: "Pakistan", text: "Pakistan" },
      //   { value: "Philippines", text: "Philippines" }];
    },
  },
  watch: {
    keyword: function () {
      var result = this.keywords.find(
        (obj) => obj.value === this.keyword
      );

      this.$emit("keywordSelected", result);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.modelSelect.$el.querySelector('input').setAttribute('aria-label', 'Select ' + this.field + ' field');
    });
  },
};
</script>

<style scoped>
.model-select-wrapper {
  margin: 5px;
}

.wbg-model-select {
  border-color: var(--action-color) !important;
  color: var(--action-color) !important;
  border-radius: var(--border-radius-sm) !important;
  /* padding: 0.375rem 0.75rem !important; */
  font-weight: 400 !important;
  font-size: 1rem !important;
}

/* .wbg-model-select .text.default { */
div.default.text {
  color: var(--action-color-hover) !important;
}
</style>