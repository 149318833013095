import Vue from 'vue'
import lodash from 'lodash'
import axios from 'axios'
import vSelect from "vue-select";
import VueCookies from 'vue-cookies'

import { setupCache } from 'axios-cache-adapter';

// import Vuex from 'vuex'

// import Plotly from 'plotly.js'
import VueLodash from 'vue-lodash'
// import VueAxios from 'vue-axios'

// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { BootstrapVue } from 'bootstrap-vue'
import VueFriendlyIframe from 'vue-friendly-iframe'
import checkView from 'vue-check-view'

import router from './router'
import App from './App.vue'
import config from './config'

// CSS files
// import 'bootstrap/dist/css/bootstrap.css'
import "vue-select/dist/vue-select.css"
import 'vue-search-select/dist/VueSearchSelect.css'

Vue.config.productionTip = false
Vue.prototype.$http = axios
// Vue.prototype.$Plotly = Plotly
Vue.prototype.$window = window
Vue.prototype.$config = config

Vue.prototype.$wait = ms => new Promise(resolve => setTimeout(resolve, ms));

// Set global Cache-Control request header for security.
Vue.prototype.$http.defaults.headers.common["Cache-Control"] = "max-age=0";

// Set up a cached http ref
const cache = setupCache({
    maxAge: 60 * 60 * 1000, // 1 hour
});

Vue.prototype.$httpCached = axios.create({
    adapter: cache.adapter,
});

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)

Vue.use(VueFriendlyIframe)
Vue.use(VueLodash, { lodash: lodash })
// Vue.use(VueAxios, axios)

Vue.use(checkView)
Vue.component("v-select", vSelect)

Vue.use(VueCookies)
Vue.$cookies.config('1d')

// Vue.use(Vuex)

var store = {
    debug: false,
    state: {
        // isLoggedIn: config.login.login_key === Vue.$cookies.get(config.login.login_cookie_key),
        isLoggedIn: true,
    },
    setLoginKey(user_login_key) {
        if (user_login_key === config.login.login_key) {
            this.state.isLoggedIn = true;
            // // this.$store.commit("setLoginKey", this.user_login_key);
            Vue.$cookies.set(config.login.login_cookie_key, user_login_key, config.login.login_expires);

            if (router.currentRoute.query.redirect === undefined || router.currentRoute.query.redirect == "/") {
                window.location.reload();
            } else {
                router.push({ path: router.currentRoute.query.redirect });
            }
        } else {
            alert("Incorrect login key");
        }
    },
    isLoginValid() {
        // Remove the login requirement, so always return login check as valid.
        return true;
        // if (config.login.login_key === Vue.$cookies.get(config.login.login_cookie_key)) {
        //     return true;
        // }
        //     return false;
    }
}
Vue.prototype.$store = store

Vue.prototype.$wbgData = window.wbgData;

function setSimpleTrackingData(pageName, pageCategory, contentType) {
    delete window.wbgData.siteSearch;  // Clear any siteSearch data

    window.wbgData.page.pageInfo.pageName = pageName;
    window.wbgData.page.pageInfo.pageCategory = pageCategory;
    window.wbgData.page.pageInfo.contentType = contentType;
}

Vue.prototype.$simpleTrack = (pageName, pageCategory, contentType) => {
    if (window._satellite) {
        setSimpleTrackingData(pageName, pageCategory, contentType)

        window._satellite.track("dynamicdata");
        console.log("Tracking submitted...", window.wbgData)
    }
}

Vue.prototype.$searchTrack = (pageName, pageCategory, contentType, siteSearch) => {
    if (window._satellite) {
        setSimpleTrackingData(pageName, pageCategory, contentType)

        if (siteSearch) {
            window.wbgData.siteSearch = siteSearch
        }

        window._satellite.track("dynamicdata");
        console.log("Tracking submitted...", window.wbgData)
    }
}

router.beforeEach((to, from, next) => {
    if (to.name === 'home') {
        next() // explicitly continue to home
    } else if (to.meta && to.meta.requiresAuth === false) {
        next() // skip auth if route is explicitly set to not require auth
    } else if (store.state.isLoggedIn) {
        next() // continue if logged in
    } else {
        next({ name: 'home', query: { redirect: to.fullPath }, hash: "#login-section" }) // default to home page
    }
})

window.app = new Vue({
    router,
    render: h => h(App),
}).$mount('#app')

// (dev) vscode@82f481d7f9a2:/workspace/app/app_kcp$ npm run serve
// (dev) vscode@b480286b17eb:/workspace/app/app_kcp$ ./node_modules/.bin/vue-cli-service serve --port 8000 --host 0.0.0.0
// https://github.com/nodejs/help/issues/3644#issuecomment-991971902
// curl -fsSL https://deb.nodesource.com/setup_12.x | sudo -E bash -
// sudo apt-get install -y nodejs
