<template>
  <!-- https://www.canva.com/design/DAE9DE6BIiU/C7wpuap1pgRnrretApdDFQ/view?utm_content=DAE9DE6BIiU&utm_campaign=share_your_design&utm_medium=link&utm_source=shareyourdesignpanel -->
  <div style="font-family: Open Sans; color: #545454">
  <!-- <div style="font-family: Open Sans; color: #545454; background: linear-gradient(0deg, #066ecb 0%, #29c1ec 100%);"> -->
    <div class="nlp-banner d-flex flex-column flex-lg-row justify-content-around" :class="$store.isLoginValid() ? 'logged-in-banner' : ''">
      <b-container style="margin: auto;">
        <!-- <b-row style="margin-top: 100px"> -->
        <b-row>
          <b-col offset-md="2" offset-lg="2">
            <!-- <div class="banner-title">NLP4Dev</div> -->
            <h1 class="banner-title">NLP4Dev</h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col offset-md="2" offset-lg="2">
            <div class="text-left banner-subtitle">
              Improving knowledge discovery with
              <br />
              Natural Language Processing models and tools
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div v-b-modal.modal-login class="login-button" aria-label="Login button" v-if=!$store.isLoginValid()>
          <b-modal id="modal-login" title="Login" hide-footer>
            <b-container>
              <b-row id="login-section">
                <b-col>
                  <p class="text-center">
                  The NLP Explorer presents the approaches and results of exploratory work undertaken by the World Bank Development Data Group to improve data discoverability using NLP methods and tools.
                  </p>
                  <p class="text-center">
                    Due to the exploratory nature of this work, access to the site is currently restricted. Please send an e-mail to
                    <a href = "mailto:nlpexplorer@worldbank.org?subject = NLP4Dev login key request">nlpexplorer@worldbank.org</a> to request for the login key.
                  </p>
                </b-col>
              </b-row>

              <br/>
              <b-row>
                <b-col>
                  <center>
                    <b-input-group class="mt-3">
                      <b-form-input v-model="user_login_key" placeholder="Input your login key" v-on:keyup.enter="$store.setLoginKey(user_login_key)"></b-form-input>
                      <b-input-group-append>
                        <b-button variant="primary" @click="$store.setLoginKey(user_login_key)">Access NLP Explorer</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </center>
                </b-col>
              </b-row>
            </b-container>
          </b-modal>
      </div>
    </div>
    <div>
      <b-container style="margin-top: 5rem; margin-bottom: 5rem">
        <b-row>
          <b-col cols="12" md="5" style="margin-bottom: 20px">
            <h2><img alt="Problem icon" title="Problem icon" src="/static/files/problem-icon.svg" style="width:4rem; height:4rem;margin-right:2rem;"/>The Problem</h2>
            <br />
            <p>
              Most data catalogs rely exclusively on lexical (full-text) search
              engines, which perform unsatisfactorily. Commercial search engines like Google
              or Bing implement more advanced methods and tools, but also perform
              inadequately when tasked to discover data.
            </p>
            <p>
              Improving data discoverability and accessibility requires (i) better
              metadata (structured, augmented), and (ii) improved search engines
              with semantic search capability and recommender systems.
            </p>
          </b-col>
          <b-col cols="0" md="2"> </b-col>
          <b-col cols="12" md="5" style="margin-bottom: 20px">
            <h2><img alt="Approach icon" title="Approach icon"  src="/static/files/approach-icon.svg" style="width:4rem; height:4rem;margin-right:2rem;"/>Our Approach</h2>
            <br />
            <p>To address these issues, our work includes:</p>
            <ul>
              <li>
                <p>The promotion and development of metadata standards and related
              tools and guidelines,
                </p>
              </li>
              <li>
                <p>Exploratory work on the use of NLP to enable semantic
                  searchability and build recommender systems, and
                </p>
              </li>
              <li>
                <p>The development of the NADA cataloguing application.</p>
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-container>

      <b-container class="home-section">
        <div class="line"></div>
        <div class="home-section-header">
          Explore our corpus of {{ corpus_size.toLocaleString() }} documents
        </div>
        <div v-if="last_update_date" class="small text-center">
          Last updated on {{ last_update_date.toDateString() }}
        </div>

        <b-row class="wbg-home-search">
          <b-container>
            <b-row class="justify-content-center">
              <b-col cols="12" md="8">
                <div class="input-group mb-3 mt-3" style="display: flex">
                  <input
                    v-model="search_text"
                    type="text"
                    class="form-control wbg-search-text pl-4"
                    :placeholder="uploaded_file ? '' : 'Enter your keywords...'"
                    aria-label="Field for search"
                    v-on:keyup.enter="sendToSearch()"
                  />
                  <div v-if="hasUploadedFile" class="wbg-uploaded-file">
                    <div class="truncated-title">
                      {{ this.uploaded_file.name }}
                    </div>
                    <i
                      class="fas fa-times fa-sm ml-2"
                      @click="removeFile"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div
                    id="submit_file_explore_corpus"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Upload a PDF or TXT document to search"
                  >
                    <div class="file-input">
                      <input
                        @change="fileUpload"
                        type="file"
                        name="file-input"
                        :value="file_input"
                        :disabled="hasUploadedFile"
                        id="file-input"
                        class="file-input__input"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Upload a PDF or TXT document to search"
                        accept=".txt,.doc,.docx,.pdf"
                        aria-label="File uploaded"
                      />
                      <i
                        title="Delete query"
                        v-show="
                          (search_text && search_text.length > 0) || file_input
                        "
                        @click="clearSearchInput"
                        style="
                          position: relative;
                          margin-left: -50px;
                          margin-right: 10px;
                          padding-right: 10px;
                          padding-left: 20px;
                          z-index: 999;
                        "
                        class="fas fa-times fa-sm"
                      />
                      <label class="file-input__label white-bg" for="file-input"
                        ><i
                          class="fas fa-file-upload fa-lg"
                          :style="hasUploadedFile ? 'color: gray' : ''"
                        ></i
                      ><span style="font-size:1px">.</span></label>
                    </div>
                  </div>
                  <div class="input-group-append">
                    <button
                      @click="sendToSearch()"
                      class="btn btn-primary wbg-search-button pr-4 pl-4"
                      type="button"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" md="8" class="text-center">
                <div class="form-check form-check-inline">
                  <input
                    v-model="search_type"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="keyword"
                    checked=""
                  />
                  <label class="form-check-label" for="inlineRadio1"
                    >Keyword search</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    v-model="search_type"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="semantic"
                  />
                  <label class="form-check-label" for="inlineRadio2"
                    >Semantic search</label
                  >
                </div>
              </b-col>
            </b-row>
          </b-container>

          <div class="col-12 text-center mt-3">
            <small
              >You can also try
              <router-link to="explore/subcategories/filtering-by-topic-share" title="Filter by topic composition"
                >filtering by topic composition</router-link
              ></small
            >
          </div>
        </b-row>

        <b-container>
          <b-row class="justify-content-center">
            <b-col cols="12">
              <p>
                To train NLP models, we compiled and maintain a
                <router-link to="/explore/corpus/" title="Corpus"
                  >corpus of documents</router-link
                >
                related to social and economic issues. Topic and word embeddings
                models, and other methods are applied to provide information
                discovery solutions including
                <router-link to="/search" title="Search">lexical and semantic search</router-link
                >, filtering by topic composition, and others. A
                <router-link to="/explore/subcategories/metadata/" title="Metadata"
                  >meta-database</router-link
                >
                is created, which provides a detailed description of all documents
                and can be used as input to analysis of knowledge on development.
              </p>
              <p>
                All solutions implemented in this project rely on
                publicly-available documents and on
                <router-link to="/methods" title="Methods">open source tools</router-link>. Our
                solutions are openly accessible in our
                <router-link to="/methods/github" title="Github">GitHub repository</router-link>.
              </p>
            </b-col>
          </b-row>
        </b-container>
      </b-container>
      <b-container class="home-section">
        <div class="line"></div>
        <div class="home-section-header">
          Explore our word embeddings
        </div>

        <b-container class="home-section-content">
          <b-row class="justify-content-center">
            <b-col md="11">
              <WordEmbeddingsGraphWidget default_query="climate change" />
            </b-col>
          </b-row>
        </b-container>

        <div class="home-section-note small text-center">
          For more information, visit the
          <router-link to="explore/word-embeddings" title="Word embeddings">word embeddings</router-link>
          page
        </div>

      </b-container>

      <b-container class="home-section">
        <div class="line"></div>
        <div class="home-section-header">Analyze your document</div>

        <b-container class="home-section-content">
          <b-row class="justify-content-center">
            <b-col md="11"> <AnalyzeYourDocumentWidget /> </b-col
          ></b-row>
        </b-container>

        <div class="home-section-note small text-center">
          <p>
            Submit your own document to our text processing and modeling
            <router-link to="content-api" title="API">API</router-link>.
            <br />
            For more information, visit the
            <router-link to="explore/analyze-your-document" title="Analyze your Document"
              >Analyze your Document</router-link
            >
            page.
          </p>
        </div>

      </b-container>

      <div class="links-section">
        <b-container class="home-section-content">
          <b-row>
            <b-col cols="12" md="4">
              <div class="wbg-homepage-block">
                <div class="wbg-homepage-block__fa">
                  <!-- <i class="fas fa-search fa-lg" aria-hidden="true"></i> -->
                  <img alt="Search icon" title="Search icon" src="/static/files/search-icon.svg" class="links-icon"/>
                </div>
                <h3><router-link to="/search" title="Search">Search</router-link></h3>
                <p>
                  Find documents by applying keyword and semantic search and filters
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="4">
              <div class="wbg-homepage-block">
                <div class="wbg-homepage-block__fa">
                  <!-- <i class="fas fa-book-open fa-lg" aria-hidden="true"></i> -->
                  <img alt="Explore icon" title="Explore icon" src="/static/files/explore-icon.svg" class="links-icon"/>
                </div>
                <h3><router-link to="/explore" title="Explore">Explore</router-link></h3>
                <p>
                  Explore the corpus: geographic coverage, trends, topics,
                  embeddings, etc.
                </p>
              </div>
            </b-col>

            <b-col cols="12" md="4">
              <div class="wbg-homepage-block">
                <div class="wbg-homepage-block__fa">
                  <!-- <i class="fas fa-map-marker-alt fa-lg" aria-hidden="true"></i> -->
                  <img alt="Methods icon" title="Methods icon" src="/static/files/methods-icon.svg" class="links-icon"/>
                </div>
                <h3>
                  <router-link to="/methods" title="Methods">Methods &amp; Tools</router-link>
                </h3>
                <p>
                  Learn about the tools, techniques, and models that we used in this
                  application
                </p>
              </div>
            </b-col>

            <b-col cols="12" md="6">
              <div class="wbg-homepage-block">
                <div class="wbg-homepage-block__fa">
                  <!-- <i class="fas fa-link fa-lg" aria-hidden="true"></i> -->
                  <img alt="API icon" title="API icon" src="/static/files/api-icon.svg" class="links-icon"/>
                </div>
                <h3><router-link to="/content-api" title="API">API</router-link></h3>
                <p>Learn about, and access, our API services</p>
              </div>
            </b-col>

            <b-col cols="12" md="6">
              <div class="wbg-homepage-block">
                <div class="wbg-homepage-block__fa">
                  <!-- <i class="fab fa-github fa-lg" aria-hidden="true"></i> -->
                  <img alt="Github icon" title="Github icon" src="/static/files/github-icon.svg" class="links-icon"/>
                </div>
                <h3>
                  <router-link to="/methods/github" title="Github"> Github</router-link>
                </h3>
                <p>Review the code at our Github repo</p>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
// start::word embedding section
import WordEmbeddingsGraphWidget from "./common/WordEmbeddingsGraphWidget";
// end::word embedding section
// start::analyze your document
import AnalyzeYourDocumentWidget from "./common/AnalyzeYourDocumentWidget";
// end::analyze your document

export default {
  name: "Home",
  components: {
    // start::word embedding section
    WordEmbeddingsGraphWidget,
    // end::word embedding section
    // start::analyze your document
    AnalyzeYourDocumentWidget,
    // end::analyze your document
  },
  data: function () {
    return {
      search_text: "",
      search_type: "keyword",
      uploaded_file: null,
      file_input: null,
      search_text_cache: "",
      corpus_size: "",
      last_update: null,
      last_update_date: null,

      // Simple login logic
      user_login_key: "",
    };
  },
  mounted() {
    // window.home_vm = this;
    this.getCorpusSize();
    this.getLastUpdateDate();

    this.$simpleTrack("Home", "home", "Homepage")
  },
  computed: {
    hasUploadedFile() {
      if (this.uploaded_file !== null) {
        if (this.uploaded_file.name !== undefined) {
          return true;
        }
      }
      return false;
    },
  },
  watch:{
    $route () {
        if (this.$route.hash === '#login-section') {
          this.$bvModal.show("modal-login")
        }
    }
  },
  methods: {
    fileUpload(event) {
      this.uploaded_file = event.target.files[0];
      this.search_text_cache = this.search_text;
      this.search_text = "";
      this.search_type = "semantic";
    },
    clearSearchInput() {
      this.search_text_cache = "";
      this.removeFile();
    },
    removeFile() {
      this.uploaded_file = null;
      this.file_input = null;
      this.search_text = this.search_text_cache;
    },
    sendToSearch() {
      this.$router.push({
        name: "search",
        query: {
          search_type: this.search_type,
          search_text: this.search_text,
        },
        params: {
          uploaded_file: this.uploaded_file,
        },
      });

      return;
    },
    getCorpusSize() {
      this.$http.get("/nlp/corpus/get_corpus_size").then((response) => {
        this.corpus_size = response.data.size;
      });
    },
    getLastUpdateDate() {
      this.$http.get("/nlp/corpus/get_last_update_date").then((response) => {
        this.last_update = response.data;
        if (this.last_update) {
          this.last_update_date = new Date(this.last_update.last_update_date);
        }
      });
    },
  },
};
</script>
<style scoped>

p {
  font-family: 'Montserrat', sans-serif;
}

ul {
  /* list-style: inside; */
  padding-left: 15px;
}

.banner-title {
  font-size: 5rem;
  font-weight: bold;
  color: white;
  /* font-family: Lato; */
  font-family: 'League Spartan', sans-serif;

  text-shadow: 0px 0px 20px  rgb(0,0,0, 0.4);
}
.banner-subtitle {
  /* font-family: Open Sans; */
  font-family: 'Montserrat', sans-serif;
  /* font-weight: bold; */
  font-size: 1rem;
  color: white;
}
.nlp-banner {
  background: url("/static/files/nlp4dev-banner.svg");
  /* background-repeat: repeat-x; */
  /* background-blend-mode: multiply; */
  background-size: cover;
  background-position: 40% 53%;
  height: 600px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;

  /* flex-direction: row;
  @media (min-width: 600px){
    flex-direction: column;
  } */

}

.login-button {
  background: url("/static/files/login-request-key-button.svg");
  background-size:     cover;
  background-repeat:   no-repeat;
  background-position: center center;
  /* margin-top: auto;
  margin-bottom: 50px; */
  font-size: 1rem;
  margin: auto 50px 50px 50px;
  /* background-color: white; */
  padding: 10px;
  /* border-radius: 20px; */
  min-height: 100px;
  min-width: 200px;
}

.logged-in-banner {
  /* margin-top: 0px !important; */
  /* Add this to offset for the nav height. */
  /* margin-top: 62.5px;  */
}

.home-section {
  /* height: 50px; */
  /* background-color: #1170b9; */
  /* background-color: #fcfcfc; */
  padding: 30px;
  /* color: white; */
  /* margin: 100px 0px 0px 0px; */
  margin-top: 50px;
}

.home-section a {
  /* color: #34b1ea; */
  color: #2A7AB0
}


.links-section {
  background-color: #343886;
  padding-top: 75px;
  margin-top: 75px;
  color: white;
}

.links-section a {
  color: white;
  text-decoration: underline
}

.links-icon {
  width:5rem;
  height:5rem;
}

.line{
  width: 150px;
  /* height: 100px; */
  border-top: 3px solid #8195d1;
  /* position: absolute; */
  margin-bottom: 20px;
}

.home-section-header {
  /* color: white; */
  font-size: 2.5rem;
  margin-bottom: 10px;
  /* text-align: left !important; */
}

.home-section-content {
  margin-top: 50px;
}

.home-section-note {
  margin-top: 50px;
}

.truncated-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.white-bg {
  background-color: white;
}
.fa-times:hover {
  color: red;
}
</style>
