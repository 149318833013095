<template>
  <div>
    <header class="blog-header wbg-internal-header">
      <div class="container">
        <div class="col-12 mb-2">
          <div id="breadcrumbs">
            <router-link to="/">Home</router-link>
            <span class="wbg-breadcrumb-separator">/</span>
            <router-link to="/search">Search</router-link>
          </div>
        </div>
        <div class="col-12">
          <i class="fas fa-search fa-2x" aria-hidden="true"></i>
          <h1>Search</h1>
          <p></p>
        </div>
      </div>
    </header>

    <div class="container flowing">
      <div class="row">
        <div class="col-12 col-md-12 mb-2">
          <div class="row justify-content-center">
            <div class="col-12 col-md-9 col-lg-8">
              <div class="input-group wbg-input-group mb-3 mt-3" style="display: flex">
                <input v-model="query" type="text" class="form-control wbg-search-text pl-4"
                  :placeholder="uploaded_file ? '' : 'Enter your keywords...'" aria-label="Field for search"
                  v-on:keyup.enter="sendSearch()" />
                <div v-if="hasUploadedFile" class="wbg-uploaded-file">
                  <div class="truncated-title">
                    {{ this.uploaded_file.name }}
                  </div>
                  <i class="fas fa-times fa-sm ml-2" @click="removeFile" aria-hidden="true"></i>
                </div>

                <div id="advanced_search" data-toggle="tooltip" data-placement="bottom" title="Advanced search">
                  <i title="Delete query" v-show="(query && query.length > 0) || file_input" @click="clearSearchInput"
                    style="
                        position: relative;
                        margin-left: -50px;
                        margin-right: 10px;
                        padding-right: 10px;
                        padding-left: 20px;
                        z-index: 999;
                      " class="fas fa-times fa-sm" />
                  <label class="file-input__label"><i class="fas fa-cog fa-lg"
                      @click="$bvModal.show('modal-scoped-advanced_search')"></i></label>
                </div>

                <b-modal id="modal-scoped-advanced_search" size="lg" title="Advanced search" hide-footer>
                  <AdvancedSearchPanel ref="advancedSearchPanel" :baseQuery="query"
                    @advancedSearch="sendAdvancedSearch" />
                </b-modal>

                <div id="submit_file" data-toggle="tooltip" data-placement="bottom"
                  title="Upload a PDF or TXT document to search">
                  <div class="file-input">
                    <input @change="fileUpload" type="file" name="file-input" :value="file_input"
                      :disabled="hasUploadedFile" id="file-input" class="file-input__input" data-toggle="tooltip"
                      data-placement="bottom" title="Upload a PDF or TXT document to search"
                      accept=".txt,.doc,.docx,.pdf" aria-label="File upload search" />
                    <label class="file-input__label" for="file-input"><i class="fas fa-file-upload fa-lg"
                        :style="hasUploadedFile ? 'color: gray' : ''"></i><span style="font-size:1px">.</span></label>
                  </div>
                </div>
                <div class="input-group-append">
                  <button @click="sendSearch()" class="btn btn-primary wbg-search-button pr-4 pl-4" type="button">
                    Search
                  </button>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-12 text-center">
              <span v-if="suggestions.length > 0" class="keyword-suggestions">
                <span class="suggestions">Suggestions:</span>
                <span @click="searchSuggestion(word)" v-for="word in suggestions" :key="'suggest_' + word"
                  class="suggestion"><u>{{ word.replace(/_/g, " ") }}</u>
                </span>
                <br />
                <br />
              </span>
            </div>

            <p v-if="uploaded_file && uploaded_file.size > 2000000">
              <span style="color: red">File size is large: ~{{
                Math.round(uploaded_file.size / 1000000)
              }}
                MB. Results may take some time to render...</span>
            </p>

            <div class="col-12 col-md-12 text-center">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="keyword"
                  :checked="!uploaded_file" v-model="search_type" :disabled="uploaded_file" @change="resetFrom" />
                <label class="form-check-label" for="inlineRadio1">Keyword search</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                  value="semantic" v-model="search_type" @change="resetFrom" />
                <label class="form-check-label" for="inlineRadio2">Semantic search</label>
              </div>
            </div>
            <div class="col-12 text-center mt-3">
              <small>You can also try
                <router-link to="explore/subcategories/filtering-by-topic-share">filtering by topic
                  composition</router-link></small>
            </div>
          </div>
          <hr />
        </div>
        <a id="results"></a>
        <aside class="col-sm-3" id="blog-sidebar-static">
          <!-- <SearchFilter
            v-if="facets"
            :filters="selected_facets"
            :facets="facets"
            @filterChanged="setFilters"
          /> -->

          <div>
            <!-- <div v-if="search_type !== 'semantic'"> -->
            <div id="filter-by-access" class="
                sidebar-filter
                wb-ihsn-sidebar-filter
                filter-by-year filter-box
              ">
              <div class="filter-header"><i class="fa fa-search pr-2"></i>Year</div>
              <div class="sidebar-filter-entries">
                <input type="hidden" />

                <b-row>
                  <b-col cols="6"><span>From</span>
                    <select name="from" id="from" v-model="selected_facets.min_year" class="form-control"
                      aria-label="Year from">
                      <option v-for="year_offset in curr_year - start_year" :key="
                        'from-' +
                        ((selected_facets.max_year || curr_year + 1) -
                          year_offset)
                      " :value="
  (selected_facets.max_year || curr_year + 1) -
  year_offset
">
                        {{
                        (selected_facets.max_year || curr_year + 1) -
  year_offset
                        }}
                      </option>
                      <option :value="start_year" selected>
                        {{ start_year }}
                      </option>
                    </select></b-col>
                  <b-col cols="6"><span>To</span>
                    <select name="to" id="to" v-model="selected_facets.max_year" class="form-control"
                      aria-label="Year to">
                      <option v-for="year_offset in selected_facets.min_year
                      ? curr_year + 1 - selected_facets.min_year
                      : curr_year - start_year + 1" :key="'to-' + (curr_year + 1 - year_offset)"
                        :value="curr_year + 1 - year_offset">
                        {{ curr_year + 1 - year_offset }}
                      </option>
                    </select>
                  </b-col>
                </b-row>
              </div>
            </div>

            <!-- <div class="sidebar-filter wb-ihsn-sidebar-filter filter-box"> -->
            <div class="sidebar-filter wb-ihsn-sidebar-filter filter-box" v-if="search_type !== 'semantic'">
              <div v-b-toggle.der_regions-collapse class="filter-header">
                <i class="fa fa-filter pr-2"></i> Geographic region
              </div>
              <b-collapse id="der_regions-collapse">
                <b-card class="facet-options">
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group v-model="selected_facets.der_regions"
                      :options="getFacetOptions('der_regions')" :aria-describedby="ariaDescribedby" name="der_regions"
                      stacked></b-form-checkbox-group>
                  </b-form-group>
                </b-card>
              </b-collapse>
            </div>

            <!-- <div class="sidebar-filter wb-ihsn-sidebar-filter filter-box"> -->
            <div class="sidebar-filter wb-ihsn-sidebar-filter filter-box" v-if="search_type !== 'semantic'">
              <div v-b-toggle.country-collapse class="filter-header">
                <i class="fa fa-filter pr-2"></i> Country featured
                <div id="country-tooltip" class="facet-tooltip">
                  <i class="fa fa-question-circle"></i>
                </div>
              </div>

              <b-tooltip target="country-tooltip" triggers="hover">
                The country name is included in the document metadata provided by the originating organization.
              </b-tooltip>

              <b-collapse id="country-collapse">
                <b-card class="facet-options">
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group v-model="selected_facets.country" :options="getFacetOptions('country')"
                      :aria-describedby="ariaDescribedby" name="country" stacked></b-form-checkbox-group>
                  </b-form-group>
                </b-card>
              </b-collapse>
            </div>

            <!-- <div class="sidebar-filter wb-ihsn-sidebar-filter filter-box"> -->
            <div class="sidebar-filter wb-ihsn-sidebar-filter filter-box" v-if="search_type !== 'semantic'">
              <div v-b-toggle.der_country-collapse class="filter-header">
                <i class="fa fa-filter pr-2"></i> Country mentioned
                <div id="der_country-tooltip" class="facet-tooltip">
                  <i class="fa fa-question-circle"></i>
                </div>
              </div>

              <b-tooltip target="der_country-tooltip" triggers="hover">
                The country name is mentioned at least once in the document.
              </b-tooltip>

              <b-collapse id="der_country-collapse">
                <b-card class="facet-options">
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group v-model="selected_facets.der_country"
                      :options="getFacetOptions('der_country')" :aria-describedby="ariaDescribedby" name="der_country"
                      stacked></b-form-checkbox-group>
                  </b-form-group>
                </b-card>
              </b-collapse>
            </div>

            <!-- <div class="sidebar-filter wb-ihsn-sidebar-filter filter-box"> -->
            <div class="sidebar-filter wb-ihsn-sidebar-filter filter-box" v-if="search_type !== 'semantic'">
              <div v-b-toggle.der_country_groups-collapse class="filter-header">
                <i class="fa fa-filter pr-2"></i> Country group
                <div id="der_country_groups-tooltip" class="facet-tooltip">
                  <i class="fa fa-question-circle"></i>
                </div>
              </div>

              <b-tooltip target="der_country_groups-tooltip" triggers="hover">
                The country names mentioned in the document, using a <a title="country groups curated data"
                  href="https://github.com/worldbank/wb-nlp-tools/blob/main/data/whitelists/countries/codelist.xlsx"
                  target="_blank">curated data</a>, are mapped to groups such as regional groups (e.g., ASEAN), country
                attributes (e.g., Landlocked), economic unions (e.g., EU), economic status (e.g., Low Income), etc.
              </b-tooltip>

              <b-collapse id="der_country_groups-collapse">
                <b-card class="facet-options">
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group v-model="selected_facets.der_country_groups"
                      :aria-describedby="ariaDescribedby" name="der_country_groups" stacked>
                      <b-form-checkbox v-for="opt in getFacetOptions('der_country_groups')" :key="'cg-' + opt.value"
                        :value="opt.value"><span :id="'id-' + opt.value"> {{ opt.text }}</span>
                        <b-tooltip :target="'id-' + opt.value" placement="topright">
                          {{ country_groups_names[opt.value] }}
                        </b-tooltip>
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-card>
              </b-collapse>
            </div>

            <div class="sidebar-filter wb-ihsn-sidebar-filter filter-box">
              <div v-b-toggle.major_doc_type-collapse class="filter-header">
                <i class="fa fa-filter pr-2"></i> Document type
              </div>
              <b-collapse id="major_doc_type-collapse">
                <b-card class="facet-options">
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group v-model="selected_facets.major_doc_type"
                      :options="getFacetOptions('major_doc_type')" :aria-describedby="ariaDescribedby"
                      name="major_doc_type" stacked></b-form-checkbox-group>
                  </b-form-group>
                </b-card>
              </b-collapse>
            </div>

            <div class="sidebar-filter wb-ihsn-sidebar-filter filter-box">
              <div v-b-toggle.corpus-collapse class="filter-header">
                <i class="fa fa-filter pr-2"></i> Source
              </div>
              <b-collapse id="corpus-collapse">
                <b-card class="facet-options">
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group v-model="selected_facets.corpus" :options="getFacetOptions('corpus')"
                      :aria-describedby="ariaDescribedby" name="corpus" stacked></b-form-checkbox-group>
                  </b-form-group>
                </b-card>
              </b-collapse>
            </div>


            <div v-show="false" class="sidebar-filter wb-ihsn-sidebar-filter filter-box">
              <div v-b-toggle.author-collapse class="filter-header">
                <i class="fa fa-filter pr-2"></i> Authors
              </div>
              <b-collapse id="author-collapse">
                <b-card class="facet-options">
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group v-model="selected_facets.author" :options="getFacetOptions('author')"
                      :aria-describedby="ariaDescribedby" name="author" stacked></b-form-checkbox-group>
                  </b-form-group>
                </b-card>
              </b-collapse>
            </div>

            <div class="sidebar-filter wb-ihsn-sidebar-filter filter-box" v-if="search_type !== 'semantic'">
              <b-row>
                <b-col cols="8">
                  <div class="filter-header" style="margin-bottom: 0;">
                    <img class="mr-2" src="/static/files/jdc-logo-small.png" width="20px" height="20px" />
                    JDC collection
                  </div>
                </b-col>
                <b-col cols="4">
                  <b-row>
                    <b-col cols="8">
                      <b-form-checkbox id="checkbox_app_tag_jdc" v-model="selected_facets.checkbox_app_tag_jdc"
                        name="checkbox_app_tag_jdc" :value="true" :unchecked-value="false" switch>
                      </b-form-checkbox>
                    </b-col>
                    <b-col cols="4">
                      <div id="jdc-app_tag-tooltip" class="facet-tooltip">
                        <i class="fa fa-question-circle"></i>
                      </div>
                      <b-tooltip target="jdc-app_tag-tooltip" triggers="hover">
                        This will filter the documents that were tagged using a combination of JDC keywords and a topic
                        model. These documents contain themes relevant to forced displacement that the Joint Data Center
                        (JDC) and UNHCR are interested in.
                      </b-tooltip>
                    </b-col>
                  </b-row>
                </b-col>
                <!-- </div> -->
                <!-- </b-col>
                <b-col cols="4" align-self="center"> -->
              </b-row>
            </div>

            <!-- <div class="sidebar-filter wb-ihsn-sidebar-filter filter-box"> -->
            <div class="sidebar-filter wb-ihsn-sidebar-filter filter-box" v-if="search_type !== 'semantic'">
              <div v-b-toggle.der_jdc_tags-collapse class="filter-header">
                <i class="fa fa-filter pr-2"></i> JDC keywords
                <div id="jdc-keywords-tooltip" class="facet-tooltip">
                  <i class="fa fa-question-circle"></i>
                </div>
              </div>
              <b-tooltip target="jdc-keywords-tooltip" triggers="hover">
                These keywords describe issues related to forced displacement that the Joint Data Center (JDC) and UNHCR
                are interested in, and have been extracted from the documents.
              </b-tooltip>

              <b-collapse id="der_jdc_tags-collapse">
                <b-card class="facet-options">
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group v-model="selected_facets.der_jdc_tags"
                      :options="getFacetOptions('der_jdc_tags')" :aria-describedby="ariaDescribedby" name="der_jdc_tags"
                      stacked></b-form-checkbox-group>
                  </b-form-group>
                </b-card>
              </b-collapse>
            </div>
          </div>
        </aside>
        <div class="col-sm-9 blog-main">
          <article class="blog-post">
            <div class="nada-pagination" v-show="hits.length > 0">
              <div class="
                  row
                  mt-3
                  mb-3
                  d-flex
                  justify-content-lg-between
                  align-items-center
                ">
                <div class="col-12 col-md-3 col-lg-4 mb-3 mb-md-0 small">
                  Showing <b>{{ start }}-{{ end }}</b> of
                  <b>{{ total.message }}</b> documents
                </div>
                <div class="
                    filter-action-bar
                    d-flex
                    col-12 col-md-9 col-lg-8
                    justify-content-lg-end
                  ">
                  <GetAPILinkWidget :api_link="api_link" />
                </div>
              </div>
            </div>
            <div v-if="!isSelectedFacetsEmpty()" class="active-filters-container">
              <div class="active-filters">
                <span v-if="selected_facets.min_year && selected_facets.max_year"
                  class="badge badge-default wb-badge-close remove-filter years" data-type="years" data-value="0">Year:
                  {{ selected_facets.min_year }}-{{
                    selected_facets.max_year
                  }}
                  <i @click="resetYears" class="fa fa-close"></i></span>

                <span v-if="selected_facets.min_year && !selected_facets.max_year"
                  class="badge badge-default wb-badge-close remove-filter years" data-type="years" data-value="0">Year:
                  {{ selected_facets.min_year }} - present
                  <i @click="resetYears" class="fa fa-close"></i></span>

                <span v-if="!selected_facets.min_year && selected_facets.max_year"
                  class="badge badge-default wb-badge-close remove-filter years" data-type="years" data-value="0">Year:
                  ... - {{ selected_facets.max_year }}
                  <i @click="resetYears" class="fa fa-close"></i></span>

                <span v-if="
                  selected_facets.country &&
                  selected_facets.country.length > 0
                ">
                  <span v-for="f_country in selected_facets.country" :key="'country-' + f_country" class="
                      badge badge-default
                      wb-badge-close
                      remove-filter
                      active-facets
                    " data-type="country" :data-value="f_country">Country: {{
                      processCountryKey("country", f_country)
                    }}
                    <i @click="resetCountry(f_country)" class="fa fa-close"></i></span>
                </span>

                <span v-if="
                  selected_facets.der_country &&
                  selected_facets.der_country.length > 0
                ">
                  <span v-for="fder_country in selected_facets.der_country" :key="'der_country-' + fder_country" class="
                      badge badge-default
                      wb-badge-close
                      remove-filter
                      active-facets
                    " data-type="der_country" :data-value="fder_country">Country: {{
                      processCountryKey("der_country",
                        fder_country)
                    }}
                    <i @click="resetDerCountry(fder_country)" class="fa fa-close"></i></span>
                </span>

                <span v-if="
                  selected_facets.der_country_groups &&
                  selected_facets.der_country_groups.length > 0
                ">
                  <span v-for="fder_country_groups in selected_facets.der_country_groups"
                    :key="'country-' + fder_country_groups" class="
                      badge badge-default
                      wb-badge-close
                      remove-filter
                      active-facets
                    " data-type="der_country_groups" :data-value="fder_country_groups">Country group: {{
                      fder_country_groups
                    }}
                    <i @click="resetDerCountryGroups(fder_country_groups)" class="fa fa-close"></i></span>
                </span>

                <span v-if="
                  selected_facets.major_doc_type &&
                  selected_facets.major_doc_type.length > 0
                ">
                  <span v-for="fmajor_doc_type in selected_facets.major_doc_type" :key="'country-' + fmajor_doc_type"
                    class="
                      badge badge-default
                      wb-badge-close
                      remove-filter
                      active-facets
                    " data-type="major_doc_type" :data-value="fmajor_doc_type">Type: {{ fmajor_doc_type }}
                    <i @click="resetMajorDocType(fmajor_doc_type)" class="fa fa-close"></i></span>
                </span>

                <span v-if="
                  selected_facets.der_regions &&
                  selected_facets.der_regions.length > 0
                ">
                  <span v-for="fder_regions in selected_facets.der_regions" :key="'country-' + fder_regions" class="
                      badge badge-default
                      wb-badge-close
                      remove-filter
                      active-facets
                    " data-type="der_regions" :data-value="fder_regions">Geo region: {{ fder_regions }}
                    <i @click="resetGeoRegion(fder_regions)" class="fa fa-close"></i></span>
                </span>

                <span v-if="
                  selected_facets.corpus && selected_facets.corpus.length > 0
                ">
                  <span v-for="fcorpus in selected_facets.corpus" :key="'country-' + fcorpus" class="
                      badge badge-default
                      wb-badge-close
                      remove-filter
                      active-facets
                    " data-type="corpus" :data-value="fcorpus">Source: {{ fcorpus }}
                    <i @click="resetCorpus(fcorpus)" class="fa fa-close"></i></span>
                </span>

                <span v-if="selected_facets.checkbox_app_tag_jdc"
                  class="badge badge-default wb-badge-close remove-filter active-facets" data-type="app_tag_jdc"
                  data-value="false">Collection: JDC
                  <i @click="resetAppTagJDC" class="fa fa-close"></i></span>

                <span v-if="
                  selected_facets.der_jdc_tags &&
                  selected_facets.der_jdc_tags.length > 0
                ">
                  <span v-for="fder_jdc_tags in selected_facets.der_jdc_tags" :key="'country-' + fder_jdc_tags" class="
                      badge badge-default
                      wb-badge-close
                      remove-filter
                      active-facets
                    " data-type="der_jdc_tags" :data-value="fder_jdc_tags">JDC keyword: {{ fder_jdc_tags }}
                    <i @click="resetDerJDCTags(fder_jdc_tags)" class="fa fa-close"></i></span>
                </span>

                <span v-if="
                  selected_facets.author &&
                  selected_facets.author.length > 0
                ">
                  <span v-for="fauthor in selected_facets.author" :key="'author-' + fauthor" class="
                      badge badge-default
                      wb-badge-close
                      remove-filter
                      active-facets
                    " data-type="author" :data-value="fauthor">Author: {{ fauthor }}
                    <i @click="resetAuthor(fauthor)" class="fa fa-close"></i></span>
                </span>

                <div>
                  <a @click="resetFilters" href="javascript:void(0);"
                    class="btn-reset-search btn btn-outline-primary btn-sm">Reset filters</a>
                </div>
              </div>
            </div>

            <SearchResultLoading :loading="loading && results_handler.length === 0" :size="curr_size" />
            <div v-if="results_handler.length > 0">
              <div v-for="idx in Array(curr_size).keys()" :key="'div-' + idx">
                <SearchResultCard v-if="results_handler[idx]" :result="results_handler[idx]" :match="match_stats[idx]"
                  :highlights="highlights[idx]" v-bind:key="'src' + idx" :loading="loading" :ref="'src' + idx" />
              </div>

              <!-- <SearchResultCard
                v-for="(result, idx, key) in hits"
                :result="result"
                :match="match_stats[idx]"
                :highlights="highlights[idx]"
                v-bind:key="result.id + key"
              /> -->
            </div>
            <Pagination @pageNumReceived="sendSearch" @currSizeSet="setCurrSize" :num_pages="num_pages"
              :curr_page_num="curr_page_num" :has_hits="hits.length > 0 && !no_more_hits" :page_sizes="page_sizes"
              :page_window="page_window" :next="next" :next_override="next_override" />
            <hr />
            <PageFooter :url="share_url" :share_text="share_text" />
          </article>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import qs from "qs";
import $ from "jquery";
import saveState from "vue-save-state";

import SearchResultCard from "./common/SearchResultCard";
import SearchResultLoading from "./common/SearchResultLoading";
import Pagination from "./common/Pagination";
import PageFooter from "./common/PageFooter";
import GetAPILinkWidget from "./common/GetAPILinkWidget.vue"
import AdvancedSearchPanel from "./common/search/AdvancedSearchPanel"

window.onbeforeunload = function () {
  localStorage.clear();
};

export default {
  name: "Search",
  props: {
    share_url: String,
    share_text: String,
  },
  components: {
    SearchResultCard,
    SearchResultLoading,
    Pagination,
    PageFooter,
    GetAPILinkWidget,
    AdvancedSearchPanel,
  },
  mixins: [saveState],
  mounted() {
    window.vm = this;
    // this.flowSideBar();
    this.prevent_route_change_search = false;
    this.prevent_default = false;
    this.getCountryGroupsNames();

    this.routeChangeSearch();

    this.$simpleTrack("Search", "search", "Search");
  },
  computed: {
    hasUploadedFile() {
      if (this.uploaded_file !== null) {
        if (this.uploaded_file.name !== undefined) {
          return true;
        }
      }
      return false;
    },
    suggestionBody() {
      const body = {
        model_id: this.$config.default_model.word2vec.model_id,
        raw_text: this.query,
        topn_words: this.suggestion_count,
      };
      return body;
    },

    searchParams() {
      const params = new URLSearchParams();
      params.append("model_id", this.$config.default_model.word2vec.model_id);
      params.append("query", this.query);
      if (this.selected_facets.min_year) {
        params.append("min_year", this.selected_facets.min_year);
      }
      if (this.selected_facets.max_year) {
        params.append("max_year", this.selected_facets.max_year);
      }
      if (this.selected_facets.country) {
        this.selected_facets.country.map((v) =>
          params.append("country", v)
        );
      }
      if (this.selected_facets.der_country) {
        this.selected_facets.der_country.map((v) =>
          params.append("der_country", v)
        );
      }
      if (this.selected_facets.der_country_groups) {
        this.selected_facets.der_country_groups.map((v) =>
          params.append("der_country_groups", v)
        );
      }
      if (this.selected_facets.der_regions) {
        this.selected_facets.der_regions.map((v) =>
          params.append("der_regions", v)
        );
      }
      if (this.selected_facets.der_jdc_tags) {
        this.selected_facets.der_jdc_tags.map((v) =>
          params.append("der_jdc_tags", v)
        );
      }

      if (this.selected_facets.corpus) {
        this.selected_facets.corpus.map((v) => params.append("corpus", v));
      }

      if (this.selected_facets.major_doc_type) {
        this.selected_facets.major_doc_type.map((v) =>
          params.append("major_doc_type", v)
        );
      }

      if (this.selected_facets.checkbox_app_tag_jdc !== undefined) {
        params.append("app_tag_jdc", this.selected_facets.checkbox_app_tag_jdc);
      }

      if (this.selected_facets.author) {
        this.selected_facets.author.map((v) =>
          params.append("author", v)
        );
      }

      // if (this.search_type == "keyword") {
      //   if (this.selected_facets.der_country) {
      //     this.selected_facets.der_country.map((v) =>
      //       params.append("der_country", v)
      //     );
      //   }
      //   if (this.selected_facets.der_country_groups) {
      //     this.selected_facets.der_country_groups.map((v) =>
      //       params.append("der_country_groups", v)
      //     );
      //   }
      //   if (this.selected_facets.der_regions) {
      //     this.selected_facets.der_regions.map((v) =>
      //       params.append("der_regions", v)
      //     );
      //   }
      //   if (this.selected_facets.der_jdc_tags) {
      //     this.selected_facets.der_jdc_tags.map((v) =>
      //       params.append("der_jdc_tags", v)
      //     );
      //   }
      // }

      params.append("from_result", this.from_result);
      params.append("size", this.curr_size);
      return params;
    },
    fileParams() {
      const formData = new FormData();
      formData.append("model_id", this.$config.default_model.word2vec.model_id);
      formData.append("file", this.uploaded_file);
      formData.append("from_result", this.from_result);
      formData.append("size", this.curr_size);
      return formData;
    },
    no_more_hits() {
      var next_from = this.curr_page_num * this.curr_size;

      var no_more_hits = false;
      if (next_from > this.total.value) {
        no_more_hits = true;
      }

      return no_more_hits;
    },
  },
  data: function () {
    return {
      // min_year: null,
      // max_year: null,
      curr_year: new Date().getUTCFullYear(),
      start_year: 1890,

      search_type: "keyword",
      keyword_search_api_url: this.$config.search_url.keyword,
      semantic_search_api_url: this.$config.search_url.semantic,
      file_search_api_url: this.$config.search_url.file,
      suggestion_api_url:
        this.$config.nlp_api_url.word2vec + "/get_similar_words",
      page_sizes: this.$config.pagination.page_sizes,
      suggestions: [],
      suggestion_anchor: "",
      suggestion_count: 6,
      start: 0,
      end: 0,
      next: 0,
      page_window: this.$config.pagination.page_window,
      curr_page_num: 0,
      curr_size: this.$config.pagination.size,
      num_pages: 0,
      next_override: false,

      results_handler: [],

      query: "",
      from_result: 0,
      hits: [],
      highlights: [],

      facets: null,
      selected_facets: {
        author: [],
      },
      watch_selected_facets: true,

      valid_countries: null,
      country_groups_names: null,

      // der_country: [],
      // corpus: [],
      // major_doc_type: [],
      // der_regions: [],

      api_link: "",

      match_stats: [],
      total: Object,
      errored: false,
      loading: false,
      uploaded_file: null,
      file_input: null,
      query_cache: "",

      cache_count: this.$config.pagination.page_window,

      prevent_route_change_search: false,

      search_cache: {},
      from_cache: false,
      prevent_default: false,
    };
  },
  methods: {
    trackSearchEvent() {
      this.$searchTrack("search--" + this.search_type, "search", "Search", {
        searchTerm: this.query,
        searchResults: this.total.message || "",
        searchFilters: this.searchParams.toLocaleString() || "",
        pagination: this.curr_page_num,
        searchType: this.search_type,
        section: "search",
        sortBy: "",
        tab: ""
      })
    },

    // SEARCH FILTER METHODS

    processCountryKey(facet_name, key) {
      if (!((facet_name === "country") || (facet_name === "der_country"))) {
        return key;
      }
      if (key == "Turkey") {
        return "Türkiye";
      } else {
        return key;
      }
    },
    processCountryGroupKey(facet_name, key) {
      if (facet_name !== "der_country_groups") {
        return key;
      }

      if (key.includes("_")) {
        return key
          .split("_")
          .filter((x) => x.length > 0)
          .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
          .join(" ");
      } else if (key.length > 8) {
        return key.charAt(0).toUpperCase() + key.slice(1);
      } else {
        return key.toUpperCase();
      }
    },
    processJDCTagsKey(facet_name, key) {
      if (facet_name !== "der_jdc_tags") {
        return key;
      }

      if (key.includes("_")) {
        return key
          .split("_")
          .filter((x) => x.length > 0)
          .join(" ");
      } else if (key === "unhcr") {
        return "UNHCR";
      } else {
        return key;
      }
    },
    processFacetKey(facet_name, key) {
      if ((facet_name === "country") || (facet_name === "der_country")) {
        return this.processCountryKey(facet_name, key);
      } else if (facet_name === "der_country_groups") {
        return this.processCountryGroupKey(facet_name, key);
      } else if (facet_name === "der_jdc_tags") {
        return this.processJDCTagsKey(facet_name, key);
      } else {
        return key;
      }
    },
    getFacetOptions(facet_name) {
      // const for_sorting = ["der_country", "der_country_groups", "der_regions"]
      if (!this.facets) {
        return [];
      }

      var options = this.facets["_filter_" + facet_name][
        facet_name
      ].buckets.map((o) => {
        return {
          text:
            this.processFacetKey(facet_name, o["key"]) +
            " (" +
            o["doc_count"] +
            ")",
          value: o["key"],
        };
      });

      options = options.sort((a, b) => a.text.localeCompare(b.text));

      if (facet_name === "der_country" && this.valid_countries) {
        options = options.filter((o) => {
          return this.valid_countries.includes(o.value);
        });
      }

      return options;
    },

    // SEARCH METHODS
    getNextSearchParams(from) {
      const sp = new URLSearchParams(this.searchParams.entries());
      sp.set("from_result", from);
      return sp;
    },
    resetYears() {
      this.selected_facets.min_year = null;
      this.selected_facets.max_year = null;
    },

    resetCountry(country) {
      this.selected_facets.country =
        this.selected_facets.country.filter((o) => o !== country);
    },
    resetDerCountry(der_country) {
      this.selected_facets.der_country =
        this.selected_facets.der_country.filter((o) => o !== der_country);
    },
    resetDerCountryGroups(der_country_groups) {
      this.selected_facets.der_country_groups =
        this.selected_facets.der_country_groups.filter(
          (o) => o !== der_country_groups
        );
    },
    resetDerJDCTags(der_jdc_tags) {
      this.selected_facets.der_jdc_tags =
        this.selected_facets.der_jdc_tags.filter((o) => o !== der_jdc_tags);
    },
    resetMajorDocType(major_doc_type) {
      this.selected_facets.major_doc_type =
        this.selected_facets.major_doc_type.filter((o) => o !== major_doc_type);
    },
    resetGeoRegion(der_regions) {
      this.selected_facets.der_regions =
        this.selected_facets.der_regions.filter((o) => o !== der_regions);
    },
    resetCorpus(corpus) {
      this.selected_facets.corpus = this.selected_facets.corpus.filter(
        (o) => o !== corpus
      );
    },
    resetAppTagJDC() {
      this.selected_facets.checkbox_app_tag_jdc = false;
    },
    resetAuthor(author) {
      console.log(author, this.selected_facets.author);
      this.selected_facets.author = [... this.selected_facets.author.filter(
        (o) => o !== author
      )];
    },
    resetFilters() {
      var selected_facets = JSON.parse(JSON.stringify(this.selected_facets));
      Object.keys(selected_facets).forEach(
        (k) =>
        (selected_facets[k] =
          k === "min_year" || k === "max_year" ? null :
            k === "checkbox_app_tag_jdc" ? false : [])
      );

      this.selected_facets = selected_facets;
    },
    isSelectedFacetsEmpty() {
      return Object.values(this.selected_facets).every(
        (value) => (value || []).length === 0
      );
    },
    keywordSearchBody() {
      const body = {};
      body["country"] = this.selected_facets.country;
      body["der_country"] = this.selected_facets.der_country;
      body["der_country_groups"] = this.selected_facets.der_country_groups;
      body["der_jdc_tags"] = this.selected_facets.der_jdc_tags;
      body["corpus"] = this.selected_facets.corpus;
      body["major_doc_type"] = this.selected_facets.major_doc_type;
      body["der_regions"] = this.selected_facets.der_regions;

      return body;
    },
    fileUpload(event) {
      this.uploaded_file = event.target.files[0];
      this.query_cache = this.query;
      this.query = "";
      this.search_type = "semantic";
      this.suggestion_anchor = "";
      this.suggestions = [];
    },
    clearSearchInput() {
      this.query_cache = "";
      this.removeFile();
      this.suggestions = [];
      this.checkUpdateRoute();

      this.prevent_default = false;
      this.defaultKeywordSearch();
    },
    removeFile() {
      this.uploaded_file = null;
      this.file_input = null;
      this.query = this.query_cache;
    },
    getSaveStateConfig() {
      return {
        cacheKey: "searchPage",
      };
    },
    resetFrom: function () {
      this.from_result = 0;
    },
    getSuggestions: function () {
      if (!this.query) {
        this.suggestions = [];
        return;
      }
      if (this.query === this.suggestion_anchor) {
        return;
      }
      this.suggestions = [];

      this.$http
        .post(this.suggestion_api_url, this.suggestionBody)
        .then((response) => {
          this.suggestions = response.data
            .map((o) => {
              if (
                o.score < 0.99 &&
                o.word.replaceAll("_", " ") !== this.query
              ) {
                return o.word;
              }
            })
            .filter((word) => word)
            .slice(0, this.suggestion_count - 1);
          this.suggestion_anchor = this.query;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    },
    sendAdvancedSearch: function (searchQuery) {
      this.$bvModal.hide('modal-scoped-advanced_search');
      if (searchQuery.query !== "") {
        this.query = "adv: " + searchQuery.query;
        this.sendSearch();
      }
    },
    searchSuggestion: function (word) {
      this.query = word;
      this.sendSearch();
    },
    updateRoute() {
      return (
        this.$route.query.search_text !== this.query ||
        this.$route.query.search_type !== this.search_type
      );
    },
    checkUpdateRoute() {
      if (this.updateRoute()) {
        this.$router.replace({
          name: "search",
          query: {
            search_text: this.query,
            search_type: this.search_type,
            // page: this.curr_page_num,
          },
        });
      }
    },
    sendSearch: function (page_num = 1) {
      this.prevent_route_change_search = true;
      this.prevent_default = false;
      this.watch_selected_facets = true;
      this.curr_page_num = page_num;
      var from = (page_num - 1) * this.curr_size;

      this.hits = [];
      this.match_stats = [];
      this.highlights = [];
      this.next_override = true;

      if (this.search_type == "keyword") {
        this.next_override = false;
        this.getSuggestions();
        this.sendKeywordSearch(from);
      } else if (this.search_type == "semantic") {
        if (this.uploaded_file != null) {
          this.sendFileSearch(from);
        } else {
          this.getSuggestions();
          this.sendSemanticSearch(from);
        }
      } else {
        return;
      }

      this.checkUpdateRoute();
    },
    sendKeywordSearch: function (from = 0) {
      if (from > this.total.value) {
        return;
      }
      this.query = this.query.replaceAll("_", " ");

      this.getCachedOrFetchData(from, false);
    },
    sendSemanticSearch: function (from = 0) {
      this.facets = null;
      // this.selected_facets = {};
      if (!this.query) {
        return;
      }
      // if (from > this.total.value) {
      //   return;
      // }

      this.getCachedOrFetchData(from, false);
    },
    sendFileSearch: function (from = 0) {
      this.from_result = from;
      this.loading = true;

      this.$http
        .post(this.file_search_api_url, this.fileParams)
        .then((response) => {
          this.hits = response.data.hits;
          this.match_stats = response.data.result;
          this.total = response.data.total;
          this.next = this.curr_page_num + 1;
          this.start = this.from_result + 1;
          this.end = this.from_result + this.hits.length;
          this.num_pages = Math.floor(this.total.value / this.curr_size);
          if (this.total.value % this.curr_size > 0) {
            this.num_pages += 1;
          }

          const is_first = this.results_handler.length === 0;

          for (var i = 0; i < this.curr_size; i++) {
            if (is_first) {
              this.results_handler.push(this.hits[i]);
            } else {
              this.results_handler[i] = this.hits[i];
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
          this.loading = false;
        })
        .finally(() => {
          this.trackSearchEvent();
          this.loading = false
        });
    },
    setCurrSize: function (size) {
      this.curr_size = size;
      this.sendSearch();
    },
    flowSideBar: function () {
      $(function () {
        var $sidebar = $("#blog-sidebar"),
          $window = $(window),
          offset = $sidebar.offset(),
          topPadding = 15;

        $window.scroll(function () {
          if (($window.scrollTop() > offset.top) & ($(window).width() > 768)) {
            $sidebar.stop().animate(
              {
                marginTop: $window.scrollTop() - offset.top + topPadding,
              },
              { easing: "ease", duration: 0 }
            );
          } else {
            $sidebar.stop().animate({
              marginTop: 0,
            });
          }
        });
      });
    },
    routeChangeSearch() {
      if (this.prevent_route_change_search) {
        return;
      }
      if (!this.loading && !this.prevent_default) {
        if (this.$route.query.search_type !== undefined) {
          this.search_type = this.$route.query.search_type;

          if (this.$route.query.search_text !== undefined) {
            this.query = this.$route.query.search_text;

            var page_num = 1;
            if (this.$route.query.page !== undefined) {
              page_num = Number(this.$route.query.page);
            }

            if (this.$route.query.author !== undefined) {
              this.watch_selected_facets = false;
              this.selected_facets.author = [... new Set(this.selected_facets.author.concat(this.$route.query.author))];
            }

            if (this.$route.params.uploaded_file) {
              this.uploaded_file = this.$route.params.uploaded_file;
            }
            this.sendSearch(page_num);
          } else if (this.$route.query.author !== undefined) {
            this.watch_selected_facets = true;
            this.selected_facets.author = [... new Set(this.selected_facets.author.concat(this.$route.query.author))];
          }
        } else {
          this.defaultKeywordSearch();
        }
      }
    },
    getCachedOrFetchData: function (from = 0, for_cache = false) {
      // :from is in the unit of actual result count

      var search_cache_key = null;

      let searchParams = null;
      let params = null;
      const is_keyword_search = this.search_type === "keyword";

      if (!is_keyword_search && !this.query) {
        // If the method is semantic search
        // and there query is empty, don't send
        // to the server.
        return
      }

      const api_url = is_keyword_search
        ? this.keyword_search_api_url
        : this.semantic_search_api_url;

      if (!for_cache) {
        this.from_result = from;
        this.loading = true;

        searchParams = this.searchParams;
        params = { params: searchParams };

        search_cache_key =
          this.search_type + "::" + searchParams.toLocaleString();
        const cached = this.search_cache[search_cache_key];
        console.log(cached);

        if (cached) {
          this.prevent_default = true;
          this.from_cache = true;

          this.hits = cached.hits;
          this.facets = cached.facets;
          this.match_stats = cached.result;
          this.total = cached.total;

          if (is_keyword_search) {
            this.highlights = cached.highlights;
            // this.selected_facets = cached.filters;
            this.valid_countries = cached.valid_countries;
          }

          this.next = this.curr_page_num + 1;
          this.start = this.from_result + 1;
          this.end = this.from_result + this.hits.length;
          this.num_pages = Math.floor(this.total.value / this.curr_size);
          if (this.total.value % this.curr_size > 0) {
            this.num_pages += 1;
          }

          const is_first = this.results_handler.length === 0;

          for (var i = 0; i < this.curr_size; i++) {
            if (is_first) {
              this.results_handler.push(this.hits[i]);
            } else {
              this.results_handler[i] = this.hits[i];
            }
          }

          this.api_link =
            location.origin + api_url + "?" + searchParams.toLocaleString();

          let vm = this;
          setTimeout(() => {
            vm.loading = false;
          }, 10);
          // this.loading = false;
          this.trackSearchEvent();

          this.fetchForCache(this.next, this.cache_count);

          return;
        }
        this.from_cache = false;
      } else {
        searchParams = this.getNextSearchParams(from);
        params = { params: searchParams };

        search_cache_key =
          this.search_type + "::" + searchParams.toLocaleString();
        const cached = this.search_cache[search_cache_key];

        if (cached) {
          return;
        }
      }

      this.$http
        .get(api_url, params)
        .then((response) => {
          if (!for_cache) {
            this.hits = response.data.hits;
            this.facets = response.data.facets;
            this.match_stats = response.data.result;
            this.total = response.data.total;

            if (is_keyword_search) {
              this.highlights = response.data.highlights;
              // this.selected_facets = response.data.filters;
              this.valid_countries = response.data.valid_countries;
            }

            this.search_cache[search_cache_key] = {
              key: search_cache_key,
              hits: response.data.hits,
              facets: response.data.facets,
              filters: response.data.filters,
              result: response.data.result,
              total: response.data.total,
            };

            if (is_keyword_search) {
              this.search_cache[search_cache_key].highlights =
                response.data.highlights;
              // this.search_cache[search_cache_key].facets = response.data.facets;
              // this.search_cache[search_cache_key].filters =
              //   response.data.filters;
              this.search_cache[search_cache_key].valid_countries =
                response.data.valid_countries;
            }

            this.next = this.curr_page_num + 1;
            this.start = this.from_result + 1;
            this.end = this.from_result + this.hits.length;

            this.num_pages = Math.floor(this.total.value / this.curr_size);
            if (this.total.value % this.curr_size > 0) {
              this.num_pages += 1;
            }

            const is_first = this.results_handler.length === 0;

            for (var i = 0; i < this.curr_size; i++) {
              if (is_first) {
                this.results_handler.push(this.hits[i]);
              } else {
                this.results_handler[i] = this.hits[i];
              }
            }

            this.api_link =
              location.origin + api_url + "?" + searchParams.toLocaleString();

            this.loading = false;
            this.trackSearchEvent();

            this.fetchForCache(this.next, this.cache_count);

            // this.getCachedOrFetchData(
            //   (this.next - 1) * this.curr_size,
            //   true
            // );
          } else {
            this.search_cache[search_cache_key] = {
              key: search_cache_key,
              hits: response.data.hits,
              facets: response.data.facets,
              filters: response.data.filters,
              result: response.data.result,
              total: response.data.total,
            };

            if (is_keyword_search) {
              this.search_cache[search_cache_key].highlights =
                response.data.highlights;
              // this.search_cache[search_cache_key].facets = response.data.facets;
              // this.search_cache[search_cache_key].filters =
              //   response.data.filters;
              this.search_cache[search_cache_key].valid_countries =
                response.data.valid_countries;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
          this.loading = false;
        })
        .finally(() => (this.loading = false));
    },
    fetchForCache(next, cache_count = 1) {
      for (var i = next; i < next + cache_count; i++) {
        this.getCachedOrFetchData((i - 1) * this.curr_size, true);
      }
    },
    defaultKeywordSearch() {
      if (!this.loading && !this.prevent_default) {
        this.sendKeywordSearch(0);
      }
    },
    getCountryGroupsNames() {
      this.$http
        .get("/nlp/static/geo/get_country_groups_names")
        .then((response) => {
          this.country_groups_names = response.data.country_groups_names;
        });
    },
  },
  watch: {
    search_type: function () {
      this.sendSearch();
    },

    selected_facets: {
      deep: true,
      handler() {
        if (this.watch_selected_facets) {
          this.prevent_default = false;
          this.defaultKeywordSearch();
        } else {
          // We set this variable to false before changing the selected_facets variable.
          // Once changed, it automatically resets.
          this.watch_selected_facets = true;
        }
      },
    },
    $route() {
      if (this.$route.query.send_search) {
        this.prevent_route_change_search = false;
        let query = Object.assign({}, this.$route.query);
        delete query.send_search;
        this.$router.replace({ query });
      }
      this.routeChangeSearch();
      window.location.hash = "#results";
    },
    // "$route.query": {
    //   deep: true,
    //   handler() {
    //     this.routeChangeSearch();
    //     window.location.hash = "#results";
    //   }
    // },
  },
};

// allowDefaultSearch
//
</script>

<style scoped>
@import "../../node_modules/bootstrap-vue/dist/bootstrap-vue.css";

u {
  border-bottom: 1px dotted;
  text-decoration: none;
}

.content-row {
  min-height: 50vh;
}

/* @media (min-width: 1200px) {
  .flowing {
    max-width: 1200px;
  }
} */

.wbg-internal-header {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/*
.blog-main {
  margin: 0;
  padding-left: 1rem;
}

.blog-sidebar {
  margin-left: -2rem;
  padding: 0;
} */

.truncated-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.active-filters-container {
  margin: 20px 0 30px;
  border-radius: 3px;
  position: relative;
}

.active-filters-container .active-filters {
  background: #f3f3f3;
  overflow: auto;
  color: white;
  clear: both;
  padding: 10px;
}

.active-filters-container .active-filters .years {
  background-color: #787878;
}

.active-filters-container .active-filters .active-facets {
  background-color: #787878;
}

.active-filters-container .wb-badge-close {
  padding: 10px 10px;
  margin: 2px 4px 2px 0;
  position: relative;
  padding-right: 10px;
  cursor: pointer;
  transition: opacity 0.35s;
}

.btn-outline-primary {
  color: #0071bc;
}

.keyword-suggestions {
  width: 100%;
  margin-left: 15px;
  padding: 5px;
  padding-bottom: 25px;
}

.suggestions {
  margin-right: 10px;
}

.suggestion {
  color: #0062cc;
  margin-right: 10px;
  cursor: pointer;
}

.fa-times:hover {
  color: red;
}

.filter-box {
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
  margin-bottom: 10px;
  background-color: #f3f3f3;
}

.facet-options {
  border: 0px;
  background-color: #f3f3f3;
  max-height: 300px;
  overflow-y: scroll;
}

div.facet-options div.card-body {
  padding: 0.25rem;
}

.flowing {
  width: 80%;
  max-width: 80rem;
  margin: auto;
}

.filter-header {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.facet-tooltip {
  float: right;
  font-size: 0.75rem;
  color: #939393;
}
</style>