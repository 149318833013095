<template>
  <div id="appHeader" :class="{ 'sticky-header': $route.name === 'home' }">
    <!-- <noscript>
      <strong
        >We're sorry but <%= htmlWebpackPlugin.options.title %> doesn't work
        properly without JavaScript enabled. Please enable it to
        continue.</strong
      >
    </noscript> -->

    <!-- <div class="container">
      <div class="row mb-3">
        <div class="col-12 col-md-8 col-lg-8 mt-3 wbg-header-title__block">
          <div class="wbg-title">NLP Explorer</div>
          <div class="wbg-subtitle">
            Natural Language Processing models and tools applied to knowledge
            discovery
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="container">
      <div class="row mb-3">
        <router-link to="/" style="text-decoration: none;">
          <div class="col-12 col-md-8 col-lg-8 mt-3">
            <div class="wbg-title">NLP4Dev</div>
            <div class="wbg-subtitle">
              Natural Language Processing for development and knowledge discovery
            </div>
          </div>
        </router-link>
      </div>
    </div> -->

    <div>
    <!-- <div v-if=$store.isLoginValid()> -->
      <b-navbar toggleable="lg" aria-label="Main navbar" id="mainNavbar" :class="{ 'transparent-header': $route.name === 'home' && !isUserScrolling }">
        <div>
        <!-- <div v-if="$route.name !== 'home'"> -->
          <router-link to="/"  title="Home" style="text-decoration: none;">
            <b-navbar-brand id="mainBrand">
              <div class="flex-container">
                <div class="wbg-title flex-child">NLP4Dev</div>
                <div class="d-none d-sm-block wbg-subtitle flex-child">
                  Natural Language Processing for knowledge discovery
                </div>
              </div>
            </b-navbar-brand>
          </router-link>
        </div>

        <b-navbar-toggle target="nav-text-collapse">
          <div class="nav-collapse-text">
            <i class="fas fa-bars" aria-hidden="true"></i>
            <span class="ml-1">Menu</span>
          </div>
        </b-navbar-toggle>

        <b-collapse id="nav-text-collapse" is-nav>
          <b-navbar-nav class="ml-auto" style="align-items: flex-end;">
            <!-- <b-nav-text>Navbar text</b-nav-text>
            <b-nav-item href="#">Home</b-nav-item>
            <b-nav-item href="#">Search</b-nav-item>
            <b-nav-item href="#">Explore</b-nav-item>
            <b-nav-item href="#">Methods &amp; Tools</b-nav-item>
            <b-nav-item href="#">API</b-nav-item>
            <b-nav-item href="#">About</b-nav-item> -->

              <li
                class="navbar-link nav-item"
                :class="{
                  active: $route.name.startsWith('home'),
                }"
              >
                <router-link class="navbar-link" to="/" title="Home">Home</router-link>
              </li>
              <li
                :class="{ active: $route.name.startsWith('search') }"
                class="navbar-link nav-item"
              >
                <router-link class="navbar-link" to="/search" title="Search"
                  >Search</router-link
                >
              </li>
              <li
                :class="{ active: $route.name.startsWith('explore') }"
                class="navbar-link nav-item"
              >
                <router-link class="navbar-link" to="/explore" title="Explore"
                  >Explore</router-link
                >
              </li>
              <!-- <li
                :class="{ active: $route.name.startsWith('analyze') }"
                class="navbar-link nav-item"
              >
                <router-link class="navbar-link" to="/analyze"
                  >Analyze</router-link
                >
              </li> -->
              <li
                :class="{ active: $route.name.startsWith('methods') }"
                class="navbar-link nav-item"
              >
                <router-link class="navbar-link" to="/methods" title="Methods"
                  >Methods &amp; Tools</router-link
                >
              </li>
              <li
                :class="{ active: $route.name.startsWith('content') }"
                class="navbar-link nav-item"
              >
                <router-link class="navbar-link" to="/content-api" title="API"
                  >API</router-link
                >
              </li>
              <li
                :class="{ active: $route.name.startsWith('about') }"
                class="navbar-link nav-item"
              >
                <router-link class="navbar-link" to="/about" title="About">About</router-link>
              </li>

          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>

    <!-- <div v-if=$store.isLoginValid() class="blog-masthead wbg-masthead">
      <div class="container">
        <nav class="nav blog-nav navbar wbg-navbar navbar-expand-lg">
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fas fa-bars" aria-hidden="true"></i
            ><span class="ml-1">Menu</span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
              <li
                class="navbar-link nav-item"
                :class="{
                  active: $route.name.startsWith('home'),
                }"
              >
                <router-link class="navbar-link" to="/">Home</router-link>
              </li>
              <li
                :class="{ active: $route.name.startsWith('search') }"
                class="navbar-link nav-item"
              >
                <router-link class="navbar-link" to="/search"
                  >Search</router-link
                >
              </li>
              <li
                :class="{ active: $route.name.startsWith('explore') }"
                class="navbar-link nav-item"
              >
                <router-link class="navbar-link" to="/explore"
                  >Explore</router-link
                >
              </li>
              <li
                :class="{ active: $route.name.startsWith('methods') }"
                class="navbar-link nav-item"
              >
                <router-link class="navbar-link" to="/methods"
                  >Methods &amp; Tools</router-link
                >
              </li>
              <li
                :class="{ active: $route.name.startsWith('content') }"
                class="navbar-link nav-item"
              >
                <router-link class="navbar-link" to="/content-api"
                  >API</router-link
                >
              </li>
              <li
                :class="{ active: $route.name.startsWith('about') }"
                class="navbar-link nav-item"
              >
                <router-link class="navbar-link" to="/about">About</router-link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div> -->
  </div>
</template>


<script>
// import SidePanel from "./components/SidePanel.vue";

export default {
  name: "Header",
  props: {
    nav_name: String,
  },
  data: function () {
    // return { active_el: localStorage.active_el };
    return {
      active_el: "home",
      isUserScrolling: false,
    };
  },
  methods: {
    activate: function (el) {
      // localStorage.active_el = el;
      this.active_el = el;
    },
    handleScroll() {
      this.isUserScrolling = (window.scrollY > 10);
    }
  },
  mounted() {
    this.handleDebouncedScroll = this.lodash.debounce(this.handleScroll, 10);
    window.addEventListener('scroll', this.handleDebouncedScroll);
  },

  beforeDestroy() {
    // I switched the example from `destroyed` to `beforeDestroy`
    // to exercise your mind a bit. This lifecycle method works too.
    window.removeEventListener('scroll', this.handleDebouncedScroll);
  }

  // components: {
  //   SidePanel,
  // },
};
</script>

<style scoped>
.sticky-header {
  position: fixed; /* fixing the position takes it out of html flow - knows
                   nothing about where to locate itself except by browser
                   coordinates */
  left: 0; /* top left corner should start at leftmost spot */
  top: 0; /* top left corner should start at topmost spot */
  width: 100vw; /* take up the full browser width */
  z-index: 999; /* high z index so other content scrolls underneath */
}

#mainNavbar {
  background-color: var(--action-color);
  /* background-color: hsl(204, 100%, 37%, 0) !important; */
  padding: 0 50px;
}

.transparent-header {
  /* opacity: 0.8; */
  /* background-color: hsl(204, 100%, 37%, 0.8) !important; */
  background-color: hsl(204, 100%, 37%, 0) !important;
}

/* #mainBrand {
  color: white !important;
} */

.nav-collapse-text {
  color: white;
  padding:5px 10px;
}

.wbg-title {
    font-size: 35px;
    font-weight: bold;
    color: white;
}

.wbg-subtitle {
    font-size: 12px;
    color: white;
    max-width: 150px;
    /* overflow-wrap: break-word; */
    white-space: normal;
}

#appHeader {
  /* background-color: white; */
  /* background-color: none; */
}
.flex-container {
    display: flex;
    max-width: 600px;
    align-items: center;
}

.flex-child {
    /* flex: 1 1; */
    /* flex-basis: 0%; */
    max-width: 170px;
}

.flex-child:first-child {
    margin-right: 10px;
    white-space: nowrap;
}

</style>