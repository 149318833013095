<template>
    <div>
        <div
          v-show="api_link"
          title="Get API link"
          v-b-modal.modal-lg
          class="btn btn btn-outline-success btn-sm wbg-button ml-2"
          ><i class="fab fa-searchengin"></i
        ></div>
        <b-modal id="modal-lg" size="lg" title="API link"
          >
          <div style="max-width:90vw;">
            <a :href="api_link" title="API link" target="_blank" style="word-wrap: break-word;"
              ><span style="font-family: 'Courier New', Courier, monospace">{{
                api_link
              }}</span></a
            >
          </div>
          <template #modal-footer>
            <div v-if="navigator.clipboard" class="w-100">
              <b-button
                variant="primary"
                size="sm"
                class="float-right"
                @click="copyText"
              >
                Copy to clipboard
              </b-button>
            </div>
          </template>
        </b-modal>
    </div>
</template>

<script>

export default {
    name: "GetAPILinkWidget",
    props: {
        api_link: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            navigator: window.navigator,
        };
    },
    methods: {
        copyText() {
        this.navigator.clipboard.writeText(this.api_link);
        },
    }
}
</script>

<style scoped>

</style>
