<template>
  <div class="page-container">
    <div class="title-search-group">
      <b-form-input v-model="title_query" :trim="true" placeholder="Search for a document..." v-on:keyup.enter="searchTitle()" @blur="inputBlurred" @input="inputActive"></b-form-input>
      <div class="results-list" v-show="(show_results_list || over_results)" @mouseover="over_results = true;" @mouseleave="over_results = false;">
        <div class="result-item" :key="'suggest-' + suggestion.id" v-for="suggestion in title_suggestions">
          <div @click="updateDoc(suggestion.id)">
            <a href="javascript:void(0);">
              <i class="fas fa-project-diagram fa-lg mr-1" aria-hidden="true" />
              {{ suggestion.title }}
            </a>
          </div>
          <hr/>
        </div>
      </div>
    </div>
    <br/>
    <br/>
    <a v-if="!((doc_meta === null) || (doc_meta === undefined))" :href="'/document/' + doc_meta.id" target="_blank"><h1> {{ doc_meta.title }} </h1></a>

    <br/>
    <br/>
    <RelatedDocumentsGraphWidget2
      :default_doc_id="doc_id"
      panel_layout="horizontal"
      @docMetadataReceived="docMetadataReceived"
      ref="relatedDocumentsGraphWidget"
      />
  </div>
</template>

<script>
import RelatedDocumentsGraphWidget2 from "./RelatedDocumentsGraphWidget2";

export default {
  name: "RelatedDocumentsGraphPage",
  props: {
  },
  mounted() {
    window.relVM = this;

    this.doc_id = this.$route.params.doc_id;
    this.doc_meta = null;

    console.log(
      "RelatedDocumentsGraphPage mounted: doc_id=",
      this.doc_id
    );

    // this.$refs.relatedDocumentsGraphWidget.getGraph(this.doc_id);
  },
  created: function () {
    // _.debounce is a function provided by lodash to limit how
    // often a particularly expensive operation can be run.
    // In this case, we want to limit how often we access
    // yesno.wtf/api, waiting until the user has completely
    // finished typing before making the ajax request. To learn
    // more about the _.debounce function (and its cousin
    // _.throttle), visit: https://lodash.com/docs#debounce
    this.debouncedSearchTitle = this.lodash.debounce(this.searchTitle, 500)
  },
  computed: {
  },
  components: {
    RelatedDocumentsGraphWidget2,
  },
  data: function () {
    return {
      doc_meta: null,
      doc_id: null,
      title_query: "",
      title_suggestions: [],
      titles: [],
      errored: false,
      loading: false,
      show_results_list: false,
      over_results: false,
    };
  },
  methods: {
    updateDoc(doc_id) {
      this.show_results_list = false;
      this.over_results = false;

      this.doc_id = doc_id;
      this.$refs.relatedDocumentsGraphWidget.getGraph(this.doc_id);
      this.title_query = "";
    },
    inputBlurred() {
      if (this.show_results_list === true) {
        this.show_results_list = false;
      }
    },
    inputActive() {
      if (this.show_results_list === false) {
        this.show_results_list = true;
      }
    },
    docMetadataReceived(doc_meta) {
      this.doc_meta = doc_meta;
    },
    searchTitle( search_type = "match", scope = null) {
      if (scope === null) {
        scope = this;
      }

      if (scope.title_query.length <= 2) {
        return
      }

      scope.loading = true;
      scope.$http
        .get(scope.$config.search_url.title_search, {
          params: {
            query: scope.title_query,
            search_type: search_type,
          },
        })
        .then((response) => {
          if ((search_type === "match") && (response.data.length === 0)) {
            scope.searchTitle("fuzzy", this);
          }

          scope.title_suggestions = response.data;
          scope.titles = response.data.map((d) => d.title);
        })
        .catch((error) => {
          console.log(error);
          scope.errored = true;
        })
        .finally(() => (scope.loading = false));
    },
  },
  watch: {
    title_query: function () {
      this.debouncedSearchTitle()
    }
  },
};
</script>

<style>
.page-container {
  margin: 50px;
  margin-top: 10px;
  padding: 10px;
}

.results-list {
  margin-top: 20px;
}

.result-item {
  margin-top: 5px;
}
</style>