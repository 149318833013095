<template>
  <!-- <div class="text-left"> -->
  <div>
    <div>
      <article class="blog-post">
        <p>
          First, select the models to use for both the topic and word embedding
          models. Then upload or provide a URL to the pdf or text file. Then
          click
          <span style="font-weight: bold">Process document</span> to start
          generating results.
        </p>
        <div class="row">
          <div class="col-6 fluid">
            <MLModelSelect
              @modelSelected="onModelSelectWord2Vec"
              :model_name="model_names.word2vec"
              :parent_model_run_info_id="model_options.word2vec.model_id"
              placeholder="Choose a word embedding model..."
            />
          </div>
          <div class="col-6 fluid">
            <MLModelSelect
              @modelSelected="onModelSelectTopicModel"
              :model_name="model_names.topic_model"
              :parent_model_run_info_id="model_options.topic_model.model_id"
              placeholder="Choose a topic model..."
            />
          </div>
        </div>

        <br />

        <center>
          <b-form-group label="" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="radio-group-1"
              v-model="selectedInput"
              :options="uploadOptions"
              :aria-describedby="ariaDescribedby"
              name="radio-options"
            ></b-form-radio-group>
          </b-form-group>
        </center>

        <form class="mt-4">
          <div
            v-if="selectedInput == 'file_upload'"
            class="input-group wbg-input-group mb-3 mt-3"
            style="display: flex"
          >
            <!-- @click="$refs.simFile.click()" -->
            <input
              type="text"
              class="form-control wbg-search-text pl-4"
              disabled
              aria-label="Upload file input"
            />
            <div>
              <div
                id="submit_file"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Upload a PDF or TXT document to search"
              >
                <div class="file-input">
                  <input
                    @change="fileUpload"
                    type="file"
                    ref="simFile"
                    name="file-input-similarity"
                    :value="file_input"
                    :disabled="hasUploadedFile"
                    id="file-input-similarity"
                    class="file-input__input"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Upload a PDF or TXT document to search"
                    accept=".txt,.pdf"
                    aria-label="File uploaded"
                  />
                  <label
                    class="file-input__label file-input__label__similarity"
                    for="file-input-similarity"
                    ><i
                      class="fas fa-file-upload fa-lg"
                      :style="hasUploadedFile ? 'color: gray' : ''"
                    ></i
                  ><span style="font-size:1px">.</span></label>
                </div>
              </div>
            </div>
            <div
              v-if="hasUploadedFile"
              v-show="hasUploadedFile"
              class="wbg-uploaded-file wbg-uploaded-file__similarity"
            >
              <div class="truncated-title">
                {{ this.uploaded_file.name }}
              </div>
              <i
                class="fas fa-times fa-sm ml-2"
                @click="removeFile"
                aria-hidden="true"
              ></i>
            </div>
          </div>

          <label
            v-if="selectedInput == 'url_upload'"
            class="sr-only"
            for="inlineFormInputGroup"
            >Enter URL of PDF or TXT file</label
          >
          <div v-if="selectedInput == 'url_upload'" class="input-group mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fas fa-link fa-md" aria-hidden="true"></i>
              </div>
            </div>

            <input
              type="text"
              class="form-control"
              id="inlineFormInputGroup"
              placeholder="Enter URL of file"
              v-model="url"
            />
          </div>

          <label
            v-if="selectedInput == 'text_upload'"
            class="sr-only"
            for="inlineFormTextAreaInputGroup"
            >Paste raw text here...</label
          >
          <div v-if="selectedInput == 'text_upload'" class="input-group mb-2">
            <b-form-textarea
              id="inlineFormTextAreaInputGroup"
              v-model="text_input"
              placeholder="Paste your raw text here..."
              rows="8"
              max-rows="10"
              v-on:keyup.enter="sendRequest()"
            ></b-form-textarea>
          </div>

          <p class="lead" v-if="uploaded_file && uploaded_file.size > 2000000">
            <span style="color: red"
              >File size is large: ~{{
                Math.round(uploaded_file.size / 1000000)
              }}
              MB. Results may take some time to render...</span
            >
          </p>

          <p class="lead" v-if="file_format_error && (selectedInput == 'file_upload') ">
            <span style="color: red"
              >Sorry, the format of ({{ format_error_file_name }}) is not supported. Submit a PDF or TXT file.</span>
          </p>

          <br />
          <b-button
            class="btn btn-primary wbg-button"
            @click="sendRequest()"
            :disabled="!stateReady"
            :variant="stateReady ? 'primary' : 'secondary'"
            >Process document</b-button
          >
        </form>
        <br />
        <a name="results"></a>
        <h3
          v-if="stateReady && model_option.model_id && init_click"
          class="mt-4 mb-3"
        >
          Closest documents and data
        </h3>

        <b-tabs
          v-if="stateReady && model_option.model_id && init_click"
          v-model="tabIndex"
          content-class="mt-3"
        >
          <b-tab title="Document analysis" active>
            <div class="text-center">
              <b-spinner v-show="loading"></b-spinner>
            </div>
            <div v-if="analyzed_document_data">
              <UploadedDocument
                v-show="!loading"
                :result="analyzed_document_data"
                @onLoadingStatusChanged="setAnalyzedDocLoading"
              />
            </div>
          </b-tab>

          <b-tab title="Embedding model">
            <SearchResultLoading
              :loading="loading"
              :size="model_option.curr_size" />
            <div v-show="model_option.hits.length > 0 && !errored">
              <div v-if="!loading">
                <SearchResultCard
                  v-for="(result, idx, key) in model_option.hits"
                  :result="result"
                  :match="model_option.match_stats[idx]"
                  v-bind:key="'word2vec_' + result.id + key"
                />
              </div>

              <Pagination
                @pageNumReceived="sendSearch"
                @currSizeSet="setCurrSize"
                :num_pages="model_option.num_pages"
                :curr_page_num="model_option.curr_page_num"
                :has_hits="has_hits"
                :page_sizes="page_sizes"
                :page_window="page_window"
                :next="model_option.next"
                :next_override="next_override"
              />
            </div>
            <div v-if="errored">
              No results found for the query. The server returned an error. Please try another query or document.
            </div>
          </b-tab>
          <b-tab title="Topic model">
            <SearchResultLoading
              :loading="loading"
              :size="model_option.curr_size" />
            <div v-show="model_option.hits.length > 0 && !errored">
              <div v-if="!loading">
                <SearchResultCard
                  v-for="(result, idx, key) in model_option.hits"
                  :result="result"
                  :match="model_option.match_stats[idx]"
                  v-bind:key="'topic_model_' + result.id + key"
                />
              </div>

              <Pagination
                @pageNumReceived="sendSearch"
                @currSizeSet="setCurrSize"
                :num_pages="model_option.num_pages"
                :curr_page_num="model_option.curr_page_num"
                :has_hits="has_hits"
                :page_sizes="page_sizes"
                :page_window="page_window"
                :next="model_option.next"
                :next_override="next_override"
              />
            </div>
            <div v-if="errored">
              No results found for the query. The server returned an error. Please try another query or document.
            </div>
          </b-tab>

          <b-tab title="Related data">
            <div class="text-center">
              <b-spinner v-show="loading"></b-spinner>
            </div>
            <div v-if="indicators_data">
              <DocumentRelatedIndicators
                v-show="!loading"
                :indicators_data="indicators_data"
                @onLoadingStatusChanged="setAnalyzedDocLoading"
              />
            </div>
          </b-tab>
        </b-tabs>
      </article>
    </div>
  </div>
</template>

<script>
import saveState from "vue-save-state";

import MLModelSelect from "../common/MLModelSelect";
import SearchResultLoading from "../common/SearchResultLoading";
import SearchResultCard from "../common/SearchResultCard";
import Pagination from "../common/Pagination";
import UploadedDocument from "../common/UploadedDocument";
import DocumentRelatedIndicators from "../common/DocumentRelatedIndicators";

export default {
  name: "AnalyzeYourDocumentWidget",
  components: {
    MLModelSelect,
    SearchResultLoading,
    SearchResultCard,
    Pagination,
    UploadedDocument,
    DocumentRelatedIndicators,
  },
  mixins: [saveState],
  mounted() {
    window.vmAnalyzeYourDocumentWidget = this;
    // console.log(this.model_names);
    // this.model_options.topic_model.model_id = null;
    // this.model_options.word2vec.model_id = null;
    // this.resetVars();
  },
  data() {
    return {
      // common
      tabIndex: 0,
      page_sizes: this.$config.pagination.page_sizes,
      page_window: this.$config.pagination.page_window,
      init_click: false,

      next_override: true,
      model_names: {
        word2vec: "word2vec",
        topic_model: "topic_model",
      },
      model_options: {
        topic_model: {
          upload_nlp_api_url: this.$config.search_url.lda.file,
          url_nlp_api_url: this.$config.search_url.lda.url,
          text_nlp_api_url: this.$config.search_url.lda.text,
          model_run_info_id: "",
          model_id: null,
          curr_page_num: 1,
          next: 0,
          num_pages: 0,
          from_result: 0,
          hits: [],
          match_stats: [],
          total: { value: null, message: null },
          curr_size: this.$config.pagination.size,
        },
        word2vec: {
          upload_nlp_api_url: this.$config.search_url.word2vec.file,
          url_nlp_api_url: this.$config.search_url.word2vec.url,
          text_nlp_api_url: this.$config.search_url.word2vec.text,
          model_run_info_id: "",
          model_id: null,
          curr_page_num: 1,
          next: 0,
          num_pages: 0,
          from_result: 0,
          hits: [],
          match_stats: [],
          total: { value: null, message: null },
          curr_size: this.$config.pagination.size,
        },
      },

      errors: [],
      errored: false,
      loading: false,

      analyzed_document_data: null,
      upload_analyze_document_url: null,
      text_analyze_document_url: null,
      url_analyze_document_url: null,
      analyze_document_model_id: null,

      indicators_data: null,
      indicators_model_id: null,

      file_format_error: false,
      format_error_file_name: null,

      url: "",
      uploaded_file: null,
      file_input: null,
      text_input: "",
      selectedInput: "file_upload",
      uploadOptions: [
        {
          html: "<strong>Upload PDF or TXT file</strong>",
          value: "file_upload",
        },
        {
          html: "<strong>Input URL to PDF or TXT file</strong>",
          value: "url_upload",
        },
        {
          html: "<strong>Paste your text</strong>",
          value: "text_upload",
        },
      ],

      result_state: null,
    };
  },
  computed: {
    stateReady() {
      if (this.model_options[this.model_names.word2vec].model_id !== null) {
        if (
          this.model_options[this.model_names.topic_model].model_id !== null
        ) {
          if (this.selectedInput === "url_upload") {
            if (this.url !== "") {
              return true;
            }
          } else if (this.selectedInput === "file_upload") {
            if (this.hasUploadedFile) {
              return true;
            }
          } else if (this.selectedInput === "text_upload") {
            if (this.text_input !== "") {
              return true;
            }
          }
        }
      }
      return false;
    },
    apiUrl() {
      if (this.selectedInput === "file_upload") {
        return this.model_option.upload_nlp_api_url;
      } else if (this.selectedInput === "text_upload") {
        return this.model_option.text_nlp_api_url;
      } else {
        return this.model_option.url_nlp_api_url;
      }
    },
    model_option() {
      return this.model_options[this.selectedModel];
    },
    analyzeDocumentParams() {
      const formData = new FormData();
      formData.append("model_id", this.analyze_document_model_id);

      if (this.selectedInput === "file_upload") {
        formData.append("file", this.uploaded_file);
      } else if (this.selectedInput === "text_upload") {
        formData.append("text", this.text_input);
      } else if (this.selectedInput === "url_upload") {
        formData.append("url", this.url);
      }
      return formData;
    },
    relatedIndicatorsParams() {
      const formData = new FormData();
      // formData.append("model_id", this.indicators_model_id);
      formData.append("model_id", this.model_options[this.model_names.topic_model].model_id);
      formData.append("model_id", this.model_options[this.model_names.word2vec].model_id);

      if (this.selectedInput === "file_upload") {
        formData.append("file", this.uploaded_file);
      } else if (this.selectedInput === "text_upload") {
        formData.append("text", this.text_input);
      } else if (this.selectedInput === "url_upload") {
        formData.append("url", this.url);
      }
      return formData;
    },
    apiParams() {
      const formData = new FormData();
      formData.append("model_id", this.model_option.model_id);

      if (this.selectedInput === "file_upload") {
        formData.append("file", this.uploaded_file);
      } else if (this.selectedInput === "text_upload") {
        formData.append("text", this.text_input);
      } else if (this.selectedInput === "url_upload") {
        formData.append("url", this.url);
      }
      formData.append("from_result", this.model_option.from_result);
      formData.append("size", this.model_option.curr_size);
      return formData;
    },
    selectedModel() {
      if (this.tabIndex === 0) {
        // Document analysis
        return this.model_names.topic_model;
      } else if (this.tabIndex === 1) {
        // Embedding model
        return this.model_names.word2vec;
      } else if (this.tabIndex === 2) {
        // Topic model
        return this.model_names.topic_model;
      } else if (this.tabIndex === 3) {
        // Related data
        return this.model_names.word2vec;
      } else {
        return null;
      }
    },
    hasUploadedFile() {
      if (this.uploaded_file !== null) {
        if (this.uploaded_file.name !== undefined) {
          return true;
        }
      }
      return false;
    },
    no_more_hits() {
      var next_from =
        this.model_option.curr_page_num * this.model_option.curr_size;

      var no_more_hits = false;
      if (next_from > this.model_option.total.value) {
        no_more_hits = true;
      }

      return no_more_hits;
    },
    has_hits() {
      return this.model_option.hits.length > 0 && !this.no_more_hits;
    },
  },
  methods: {
    resetVars() {
      this.init_click = false;

      this.url = "";
      this.uploaded_file = null;
      this.file_input = null;
      this.text_input = "";

      this.indicators_data = null;

      this.analyzed_document_data = null;

      this.model_options.topic_model.hits = [];
      this.model_options.word2vec.hits = [];

      this.model_options.topic_model.match_stats = [];
      this.model_options.word2vec.match_stats = [];
    },
    setCurrSize(size) {
      this.model_options[this.selectedModel].curr_size = size;
      this.sendSearch();
    },
    getSaveStateConfig() {
      return {
        cacheKey: "analyze-your-document-" + this.$route.name,
      };
    },
    onModelSelectWord2Vec(result) {
      this.model_options[this.model_names.word2vec].upload_nlp_api_url =
        this.$config.search_url[result.model_name].file;
      this.model_options[this.model_names.word2vec].url_nlp_api_url =
        this.$config.search_url[result.model_name].url;
      this.model_options[this.model_names.word2vec].text_nlp_api_url =
        this.$config.search_url[result.model_name].text;
      this.model_options[this.model_names.word2vec].model_id =
        result.model_run_info_id;

      this.indicators_model_id = result.model_run_info_id;

      console.log(result.model_run_info_id);
    },
    onModelSelectTopicModel(result) {
      this.model_options[this.model_names.topic_model].upload_nlp_api_url =
        this.$config.search_url[result.model_name].file;
      this.model_options[this.model_names.topic_model].url_nlp_api_url =
        this.$config.search_url[result.model_name].url;
      this.model_options[this.model_names.topic_model].text_nlp_api_url =
        this.$config.search_url[result.model_name].text;
      this.model_options[this.model_names.topic_model].model_id =
        result.model_run_info_id;

      this.upload_analyze_document_url =
        this.$config.analyze_document_url[result.model_name].analyze_file;
      this.url_analyze_document_url =
        this.$config.analyze_document_url[result.model_name].analyze_url;
      this.text_analyze_document_url =
        this.$config.analyze_document_url[result.model_name].analyze_text;
      this.analyze_document_model_id = result.model_run_info_id;

      console.log(result.model_run_info_id);
    },
    sendSearch: async function (page_num = 1) {
      console.log(page_num);
      console.log(this.selectedModel);

      this.errored = false;
      this.loading = true;
      console.log(this.model_options[this.selectedModel]);
      this.model_options[this.selectedModel].curr_page_num = page_num;
      var from = (page_num - 1) * this.model_option.curr_size;

      if (
        from > this.model_options[this.selectedModel].total.value &&
        !this.next_override
      ) {
        return;
      }
      // this.model_options[this.selectedModel].hits = [];
      this.model_options[this.selectedModel].from_result = from;

      await this.$http
        .post(this.apiUrl, this.apiParams)
        .then((response) => {
          this.model_options[this.selectedModel].hits = response.data.hits;
          this.model_options[this.selectedModel].match_stats =
            response.data.result;
          this.model_options[this.selectedModel].total = response.data.total;
          this.model_options[this.selectedModel].next =
            this.model_options[this.selectedModel].curr_page_num + 1;
          this.model_options[this.selectedModel].num_pages = Math.floor(
            this.model_options[this.selectedModel].total.value /
              this.model_options[this.selectedModel].curr_size
          );
          if (
            this.model_options[this.selectedModel].total.value %
              this.model_options[this.selectedModel].curr_size >
            0
          ) {
            this.model_options[this.selectedModel].num_pages += 1;
          }
        })
        .catch((error) => {
          console.log(error);
          this.errors.push(error);
          this.errored = true;
          this.loading = false
        })
        .finally(() => (this.loading = false));
    },
    async analyzeDocument() {
      this.loading = true;
      const analyze_document_url =
        this.selectedInput === "file_upload"
          ? this.upload_analyze_document_url
          : this.selectedInput === "text_upload"
          ? this.text_analyze_document_url : this.url_analyze_document_url;

      console.log(analyze_document_url);

      await this.$http
        .post(analyze_document_url, this.analyzeDocumentParams)
        .then((response) => {
          this.analyzed_document_data = response.data;
          this.loading = false;
        });
    },
    async getRelatedIndicators() {
      this.loading = true;
      const all_indicators_endpoint =
        this.selectedInput === "file_upload"
          ? "/get_similar_indicators_from_file"
          : this.selectedInput === "text_upload"
          ? "/get_similar_indicators_from_text"
          : "/get_similar_indicators_from_url";

      await this.$http
        .post(
          this.$config.extra_url.all + all_indicators_endpoint,
          this.relatedIndicatorsParams
        )
        .then((response) => {
          // this.indicators_data = response.data.ensemble;
          this.indicators_data = response.data[this.model_options[this.model_names.topic_model].model_id];
          this.loading = false;
        });
    },
    fileUpload(event) {
      this.file_format_error = false;
      this.format_error_file_name = null;

      if (event.target.files[0]) {
        if ((event.target.files[0].type == "application/pdf") || (event.target.files[0].type == "text/plain")) {
          this.uploaded_file = event.target.files[0];
        } else {
          this.file_format_error = true;
          this.format_error_file_name = event.target.files[0].name;

          this.uploaded_file = null;
          this.file_input = null;
        }
      }
    },
    removeFile() {
      this.uploaded_file = null;
      this.file_input = null;
    },
    setAnalyzedDocLoading(loading) {
      console.log(loading);
      this.loading = loading;
    },
    getInputsState() {
      return {
        selected_input: this.selectedInput,
        word2vec_model_id: this.model_options[this.model_names.word2vec].model_id,
        topic_model_model_id: this.model_options[this.model_names.topic_model].model_id,
      }
    },
    sendRequest() {
      this.init_click = true;
      this.loading = true;
      if (this.stateReady) {
        if (this.tabIndex === 0) {
          this.analyzeDocument();
        } else if (this.tabIndex === 3) {
          this.getRelatedIndicators();
        } else {
          this.sendSearch(this.model_option.curr_page_num);
        }
      } else {
        this.init_click = false;
        this.loading = false;
      }

      this.result_state = this.getInputsState()
    },
  },

  watch: {
    tabIndex: function () {
      this.sendRequest();
    },
    selectedInput: function() {
      // this.resetVars();
      this.sendRequest();
    }
  },
};
</script>
<style scoped>
.file-input__label__similarity {
  /* border-right: 1px;
  border-right-color: rgb(206, 212, 218);
  border-right-style: solid; */
  border: 0px;
  border-top: 0px;
  border-bottom: 0px;
  /* margin-left: 10px; */
}
.wbg-uploaded-file__similarity {
  position: absolute; /*relative;*/
  max-width: 90%;
  /* margin-left: 10px; */
  max-height: 100%;
  margin-top: 1px;
}
.truncated-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}
</style>
