<template>
  <div>
    <b-container>
      <b-row class="wdi-group">
        <b-row>
          <b-col col="12">
            <p class="lead">
              <a :href="result.url_wb" target="_blank">{{ result.name }}</a>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12" lg="8">
            <!-- <div class="col-4" v-view="viewHandler"> -->
            <!-- v-if="result.url_wb && viewEvent.percentInView > 0.9" -->
            <iframe
              class="wdi-frame"
              v-if="result.url_wb"
              loading="lazy"
              :src="
                'https://data.worldbank.org/share/widget?indicators=' +
                getIndicatorName(result) +
                '&view=map'
              "
              width="100%"
              height="300"
              frameBorder="0"
              scrolling="no"
            ></iframe>
            <br />
            <br />

            <a :href="result.url_data" target="_blank">Link to data</a>
            <br />
            <a :href="result.url_meta" target="_blank">Link to metadata</a>
          </b-col>

          <b-col md="12" lg="4">
            <div v-if="indicator_meta">
              <div class="wdi-definition">
                <p>
                  {{
                    indicator_meta.Shortdefinition || indicator_meta.Longdefinition
                  }}
                </p>
              </div>
            </div>
            <hr />
          </b-col>
        </b-row>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  name: "WDICard",
  props: {
    result: Object,
  },
  mounted() {
    // window.wdi_card = this;
    this.getIndicatorMetadata(this.result);
  },
  data() {
    return {
      indicator_name: null,
      indicator_meta: null,
      //   viewEvent: {
      //     type: "",
      //     percentInView: 0,
      //     percentTop: 0,
      //     percentCenter: 0,
      //   },
    };
  },
  computed: {
    searchParams() {
      const params = new URLSearchParams();
      params.append("url_meta", this.result.url_meta);
      return params;
    },
  },
  methods: {
    // viewHandler(e) {
    //   if (e.type === "exit") return;
    //   Object.assign(this.viewEvent, e);
    // },
    getIndicatorName(result) {
      if (result.url_wb) {
        var name = result.url_wb.split("/");
        this.indicator_name = name[name.length - 1];
        return this.indicator_name;
      }
    },
    getIndicatorMetadata() {
      this.$http
        .get(this.$config.extra_url.wdi + "/get_wdi_metadata", {
          params: this.searchParams,
        })
        .then((response) => {
          this.indicator_meta = response.data;
        });
    },
  },
  watch: {},
};
</script>
<style scoped>
.wdi-group {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}
.wdi-frame {
  box-sizing: content-box;
}
.wdi-definition {
  max-height: 350px;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.wdi-definition::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
</style>